import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import "./Transport.css"; // Import the CSS for styling

function Transport() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="transport-section">
      <header className="transport-header" data-aos="fade-up">
        <h1 className="transport-title">Transport Facilities</h1>
      </header>
      <div className="transport-content">
        <div className="transport-description" data-aos="fade-down">
          <p>
            Our school provides extensive transport facilities covering several routes, including Sevapet, Nemilicherry,
            Pudhunavilangai, Tamaraipakkam, Pollivakam, Ondikuppam, and Pattarai. The routes are reviewed and updated each
            year to suit the needs of students availing the service.
          </p>
          <p>
            The following are the bus routes and timings:
            <ul>
              <li><strong>Bus 1:</strong> Tamaraipakkam - Starts at 7:15 AM</li>
              <li><strong>Bus 2:</strong> Pudhunavilangai - Starts at 7:15 AM</li>
              <li><strong>Bus 3:</strong> Pollivakam - Starts at 7:30 AM</li>
              <li><strong>Bus 4:</strong> Nemilicherry - Starts at 7:25 AM</li>
              <li><strong>Bus 5:</strong> Sevapet - Starts at 7:30 AM</li>
              <li><strong>Bus 6:</strong> Ondikuppam and Pattarai - Starts at 7:30 AM</li>
            </ul>
          </p>
        </div>
        <div className="transport-rules" data-aos="fade-up">
          <h2>Important Rules for Bus/Van Users</h2>
          <ol>
            <li>The bus fee must be paid on time; defaulters will lose transport privileges immediately.</li>
            <li>Children must be ready at their designated stops at the scheduled time to avoid delays.</li>
            <li>Bus stops are fixed and will not be changed.</li>
            <li>Full term fees are collected, even if a student uses the bus for a shorter period.</li>
            <li>No refund of bus fees is allowed under any circumstances.</li>
            <li>To discontinue the service, inform the office a week in advance for attendance purposes.</li>
            <li>Tipping drivers and conductors is prohibited.</li>
            <li>If a child needs private transportation, notify the bus conductor and class teacher in advance.</li>
            <li>Behavior on the bus is strictly monitored; misbehavior can result in transport facility termination.</li>
            <li>Bus route details are included in the Student ID Card.</li>
          </ol>
        </div>
        <div className="transport-contact" data-aos="fade-down">
          <p><strong>For any queries, contact:</strong></p>
          <p>Transport Manager</p>
          <p>Mobile: 9952703424</p>
        </div>
      </div>
    </div>
  );
}

export default Transport;