import React from "react";
import "./Notification.css";

function Notification() {
  return (
    <>

      {/* Card Group Section */}
      <div className="card-group-section">
        <div className="card-group">
          <div className="card">
            <h5 className="card-title">Location</h5>
            <p className="card-text">Tiruvallur</p>
          </div>
          <div className="card">
            <h5 className="card-title">Curriculum</h5>
            <p className="card-text">CBSE</p>
          </div>
          <div className="card">
            <h5 className="card-title">Admission</h5>
            <p className="card-text">Admissions open for the Academic Year 2025-2026 for Pre.Kg to VIII and XI Grade.</p>
          </div>
          <div className="card">
            <h5 className="card-title">Infrastructure</h5>
            <p className="card-text">State of the Art</p>
          </div>
          <div className="card">
            <h5 className="card-title">Specialization</h5>
            <p className="card-text">Project-Based Learning</p>
          </div>
        </div>
      </div>
  

    {/* Second Card Group Section */}
    <div className="card-group-section">
    <div className="card-group">
      <div className="card">
        <h5 className="card-title">Notifications</h5>
        <p className="card-text">
          <strong>29th ANNUAL SPORTS MEET:</strong> Saturday, 25th January 2025 at 8:30 AM at Guru Misri Open Auditorium.
        </p>
      </div>
      <div className="card">
        <h5 className="card-title">Announcements</h5>
        <p className="card-text">
          <strong>DATE SHEET - SSE EXAMINATION 2025:</strong> Released.
          <br />
          <strong>DATE SHEET - SSCE:</strong> Released.
        </p>
      </div>
      <div className="card">
        <h5 className="card-title">Media Library</h5>
        <p className="card-text">
          <strong>29th Annual Day Celebration:</strong> "UDHAN UTSAV" of Sri R.M. Jain Group of Schools.
          <br />
          <strong>Annual Fest:</strong> 29TH ANNUAL FEST OF SRMJV FAMILY "UDHAAN UTSAV."
        </p>
      </div>
    </div>
</div>
</>

);


}

export default Notification;