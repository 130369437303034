import React from "react";
import "./SrmjvAims.css";

const SrmjvAims = () => {
  return (
    <div className="why-choose-srmjv">
      
      <div className="aims-section">
        <div className="aims-content">
          <h2>SRMJV Aims To</h2>
          <ul>
            <li>
              <i className="fas fa-lightbulb icon"></i>
              Promote a "can do" attitude and inspire each to achieve their best.
            </li>
            <li>
              <i className="fas fa-heart icon"></i>
              Educate children not merely to do the right things but to enjoy doing so.
            </li>
            <li>
              <i className="fas fa-check-circle icon"></i>
              Give 100% pass results in all the classes.
            </li>
            <li>
              <i className="fas fa-child icon"></i>
              Encourage the individuality of every child and at the same time inculcate in
              them a sense of responsibility and consideration for others.
            </li>
            <li>
              <i className="fas fa-star icon"></i>
              Strive for excellence.
            </li>
            <li>
              <i className="fas fa-hands icon"></i>
              Have empathy for fellow beings.
            </li>
            <li>
              <i className="fas fa-culture icon"></i>
              Instill in the child the values of the rich Indian culture and tradition,
              respecting every creed with tolerance.
            </li>
            <li>
              <i className="fas fa-brain icon"></i>
              Provide an enriched environment for an all-round development.
            </li>
            <li>
              <i className="fas fa-handshake icon"></i>
              Sensitize children with the value of work ethics and community service.
            </li>
            <li>
              <i className="fas fa-leaf icon"></i>
              Prepare children for leadership and personal fulfilment.
            </li>
            <li>
              <i className="fas fa-book-open icon"></i>
              Encourage children to become active and lifelong learners.
            </li>
            <li>
              <i className="fas fa-magic icon"></i>
              Adapt innovative methods to achieve academic excellence in conformity with
              psychological, pedagogical and social principles.
            </li>
            <li>
              <i className="fas fa-child icon"></i>
              Define appropriate approaches of academic activities to provide stress-free,
              child-centered and holistic education to all children without compromising
              on quality.
            </li>
          </ul>

        </div>
        
      </div>
    </div>
  );
};

export default SrmjvAims;