import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS animations
import "./ScoutesGuides.css"; // Import CSS for styling

function ScoutsGuides() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const trainingLevels = [
    { level: "Pravesh", description: "Introduction to scouting and guiding, aimed at fostering basic skills." },
    { level: "Pratham Sopan", description: "First Step - Builds discipline, teamwork, and leadership skills." },
    { level: "Dwithya Sopan", description: "Second Step - Focuses on advancing scouting techniques." },
    { level: "Tritiya Sopan", description: "Third Step - Emphasizes specialized training and personality development." },
  ];

  return (
    <div className="scouts-guides-section">
      <header className="scouts-guides-header" data-aos="fade-up">
        <h1 className="scouts-guides-title">Scouts & Guides</h1>
      </header>
      <div className="scouts-guides-content" data-aos="fade-up">
        <p>
          As part of our Personality Development and Leadership Drive, our school introduces Cubs and Bulbuls for classes III to V, and Scouts and Guides for classes VI to VIII. Students receive intensive training at various levels to shape their skills and character.
        </p>
        <div className="training-levels">
          {trainingLevels.map((training, index) => (
            <div key={index} className="training-card" data-aos="fade-up">
              <h2>{training.level}</h2>
              <p>{training.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="scouting-gallery" data-aos="fade-up">
        <img
          src={`${process.env.PUBLIC_URL}/img/scouts1.jpeg`}
          alt="Students in scouting formation"
          className="scouting-image"
        />
        <img
          src={`${process.env.PUBLIC_URL}/img/scouts2.jpeg`}
          alt="Students participating in scouting activities"
          className="scouting-image"
        />
      </div>
      <div className="scouting-gallery" data-aos="fade-up">
        <img
          src={`${process.env.PUBLIC_URL}/img/scouts3.jpeg`}
          alt="Students in scouting formation"
          className="scouting-image"
        />
        <img
          src={`${process.env.PUBLIC_URL}/img/scouts4.jpeg`}
          alt="Students participating in scouting activities"
          className="scouting-image"
        />
      </div>
      <br/>

      <div className="scouts-guides-content" data-aos="fade-up">
        <h3 className="scouthead">
        We take our students to Scouts and Guides Camp as well as trekking. 
        </h3>
        
      </div>
      <div className="scouting-gallery" data-aos="fade-up">
        <img
          src={`${process.env.PUBLIC_URL}/img/scouts5.jpeg`}
          alt="Students in scouting formation"
          className="scouting-image"
        />
        <img
          src={`${process.env.PUBLIC_URL}/img/scouts7.jpeg`}
          alt="Students participating in scouting activities"
          className="scouting-image"
        />
      </div>
      <div className="scouting-gallery" data-aos="fade-up">
        <img
          src={`${process.env.PUBLIC_URL}/img/scouts8.jpeg`}
          alt="Students in scouting formation"
          className="scouting-image"
        />
        <img
          src={`${process.env.PUBLIC_URL}/img/scouts9.jpeg`}
          alt="Students participating in scouting activities"
          className="scouting-image"
        />
          
      </div>

      <div className="scouting-gallery" data-aos="fade-up">
        
           <img
          src={`${process.env.PUBLIC_URL}/img/scouts10.jpeg`}
          alt="Students participating in scouting activities"
          className="scouting-image"
        />
      </div>


    </div>
  );
}

export default ScoutsGuides;