import React from "react";
import "./StudentsCareCell.css"; // Import the CSS for styling

function StudentsCareCell() {
  const careCellMembers = [
    {
      sNo: 1,
      name: "Smt. K Udayakumari",
      mobile: "9786261107",
      email: "udayakumarisridharan@gmail.com",
      incharge: "KG students",
    },
    {
      sNo: 2,
      name: "Smt. H Manjula",
      mobile: "9080238433",
      email: "manjulahamanthji@gmail.com",
      incharge: "1st & 2nd grade students",
    },
    {
      sNo: 3,
      name: "Smt. G Vanitha",
      mobile: "9677634825",
      email: "gvanitha2805@gmail.com",
      incharge: "3rd, 4th & 5th grade students",
    },
    {
      sNo: 4,
      name: "Sri. D Boominathan",
      mobile: "9952230586",
      email: "boominathanmwe@gmail.com",
      incharge: "6th, 7th & 8th grade students",
    },
    {
      sNo: 5,
      name: "Smt. K Sabitha",
      mobile: "9171639985",
      email: "sabitha.srmjv@gmail.com",
      incharge: "9th & 10th grade students",
    },
    {
      sNo: 6,
      name: "Smt. M. Revathy",
      mobile: "9841644813",
      email: "revathym.srmjv@gmail.com",
      incharge: "11th & 12th grade students",
    },
  ];

  return (
    <div className="care-cell-section">
      <header className="care-cell-header">
        <h1 className="care-cell-title">Students Care Cell</h1>
      </header>
      <div className="care-cell-background">
        <table className="care-cell-table">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Name</th>
              <th>Mobile No</th>
              <th>Email ID</th>
              <th>Incharge</th>
            </tr>
          </thead>
          <tbody>
            {careCellMembers.map((member) => (
              <tr key={member.sNo}>
                <td>{member.sNo}</td>
                <td>{member.name}</td>
                <td>{member.mobile}</td>
                <td>{member.email}</td>
                <td>{member.incharge}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default StudentsCareCell;