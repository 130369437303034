import React, { useState } from "react";
import Calendar from "react-calendar";
import Modal from "react-modal";
import "react-calendar/dist/Calendar.css"; // Calendar styles
import "./EventPage.css"; // Custom styles for the page

Modal.setAppElement("#root"); // Required for accessibility

function EventPage() {
  const [selectedDate, setSelectedDate] = useState(new Date()); // State for calendar selection
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [events, setEvents] = useState([
    {
      id: 1,
      date: "2025-01-25",
      name: "29th Annual Sports Meet",
      description: "Join us at Guru Misri Open Auditorium.",
    },
    {
      id: 2,
      date: "2025-02-15",
      name: "Science Exhibition",
      description: "Explore scientific innovations by our students.",
    },
    {
      id: 3,
      date: "2025-03-01",
      name: "Annual Day Celebration",
      description: "A festive day for all the students and faculty.",
    },
  ]);
  const [modalEvent, setModalEvent] = useState(null); // State for the current event in the modal

  // Get events for a specific date
  const getEventsForDate = (date) => {
    const formattedDate = date.toISOString().split("T")[0]; // Format date as YYYY-MM-DD
    return events.filter((event) => event.date === formattedDate);
  };

  // Handle calendar date click
  const handleDateClick = (date) => {
    setSelectedDate(date);
    const eventList = getEventsForDate(date);
    if (eventList.length > 0) {
      setModalEvent(eventList[0]); // Show the first event
      setIsModalOpen(true); // Open modal
    } else {
      alert("No events on this date.");
    }
  };

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="event-page">
      <h1 className="event-title">Event Calendar</h1>
      <p className="event-subtitle">Click on a date to view details about scheduled events.</p>

      {/* Calendar */}
      <Calendar
        onChange={handleDateClick}
        value={selectedDate}
        tileClassName={({ date }) => {
          const formattedDate = date.toISOString().split("T")[0];
          return events.some((event) => event.date === formattedDate) ? "event-date" : "";
        }}
      />

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Event Details"
        className="event-modal"
        overlayClassName="event-modal-overlay"
      >
        <h2>{modalEvent?.name}</h2>
        <p>{modalEvent?.description}</p>
        <p><strong>Date:</strong> {modalEvent?.date}</p>
        <button className="btn btn-primary" onClick={closeModal}>
          Close
        </button>
      </Modal>

      {/* List Upcoming Events */}
      <div className="upcoming-events">
        <h2 className="events-header">Upcoming Events</h2>
        <ul className="events-list">
          {events.map((event) => (
            <li key={event.id} className="event-item">
              <strong>{event.date}:</strong> {event.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default EventPage;