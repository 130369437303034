import React from "react";
import "./HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-section">
      {/* Background Image */}
      <img
        src={`${process.env.PUBLIC_URL}/img/school-building.jpg`}
        alt="School Building"
        className="hero-image"
      />

      {/* Overlay */}
      <div className="hero-overlay">
        <h1 className="hero-title">Sri R.M. Jain Vidyasharam Senior Secondary School</h1>
        <div className="hero-buttons d-flex justify-content-center align-items-center">
          <button className="btn btn-primary me-3">Enquire Now</button>
          <a
            href="https://www.youtube.com/watch?v=your-video-link"
            target="_blank"
            rel="noopener noreferrer"
            className="play-icon d-flex justify-content-center align-items-center"
          >
            <i className="fas fa-play-circle"></i>
          </a>
          <a
            href="https://www.youtube.com/watch?v=your-video-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn btn-outline-light">Introduction</button>
          </a>
        </div>
      </div>

      {/* Card Group Section */}
      <div className="card-group-section">
        <div className="card-group">
          <div className="card">
            <h5 className="card-title">Location</h5>
            <p className="card-text">Tiruvallur</p>
          </div>
          <div className="card">
            <h5 className="card-title">Curriculum</h5>
            <p className="card-text">CBSE</p>
          </div>
          <div className="card">
            <h5 className="card-title">Admission</h5>
            <p className="card-text">Admissions open for the Academic Year 2025-2026 for Pre.Kg to VIII and XI Grade.</p>
          </div>
          <div className="card">
            <h5 className="card-title">Infrastructure</h5>
            <p className="card-text">State of the Art</p>
          </div>
          <div className="card">
            <h5 className="card-title">Specialization</h5>
            <p className="card-text">Project-Based Learning</p>
          </div>
        </div>
      </div>
  

    {/* Second Card Group Section */}
    <div className="card-group-section">
    <div className="card-group">
      <div className="card">
        <h5 className="card-title">Notifications</h5>
        <p className="card-text">
          <strong>29th ANNUAL SPORTS MEET:</strong> Saturday, 25th January 2025 at 8:30 AM at Guru Misri Open Auditorium.
        </p>
      </div>
      <div className="card">
        <h5 className="card-title">Announcements</h5>
        <p className="card-text">
          <strong>DATE SHEET - SSE EXAMINATION 2025:</strong> Released.
          <br />
          <strong>DATE SHEET - SSCE:</strong> Released.
        </p>
      </div>
      <div className="card">
        <h5 className="card-title">Media Library</h5>
        <p className="card-text">
          <strong>29th Annual Day Celebration:</strong> "UDHAN UTSAV" of Sri R.M. Jain Group of Schools.
          <br />
          <strong>Annual Fest:</strong> 29TH ANNUAL FEST OF SRMJV FAMILY "UDHAAN UTSAV."
        </p>
      </div>
    </div>
</div>
</div>

);


}

export default HeroSection;