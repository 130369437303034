import React from "react";
import "./HeroSection.css";


function HeroSection() {
  return (
    <div className="hero-section">
      {/* Hero Image */}
      <div className="hero-image-container">
        <img
          src={`${process.env.PUBLIC_URL}/matimg/vidyapeeth.jpg`}
          alt="School Building"
          className="hero-image"
        />
      </div>

      {/* Overlay */}
      <div className="hero-overlay">
        <div className="hero-content">
          <h1 className="hero-title">
            Sri R.M. Jain Vidyashram Senior Secondary School
          </h1>
          <div className="hero-buttons">
            <button className="btn btn-primary">Enquire Now</button>
            <a
              href="https://www.youtube.com/watch?v=your-video-link"
              target="_blank"
              rel="noopener noreferrer"
              className="play-icon"
            >
              <i className="fas fa-play-circle"></i>
            </a>
            <button className="btn btn-outline-light">Introduction</button>
          </div>
        </div>
      </div>

       
    </div>
  );
}

export default HeroSection;