import React from "react";
import "./Infrastructure.css"; // Updated CSS for changes

function Infrastructure() {
  return (
    <div className="infrastructure-section">
      <header className="infrastructure-header">
        <h1 className="infrastructure-title">Infrastructure</h1>
      </header>
      <div className="infrastructure-content">
        <div className="infrastructure-text">
          <p>
            Sri R.M. Jain Vidhyashram Sr. Sec School is spread over 10,730 square meters of land. 
            The school strives to impart quality education to children, enabling holistic development through continuous improvement.
          </p>
          <p>
            All classrooms are equipped with digital boards to enhance our teaching methodology, ensuring an interactive and effective learning environment.
          </p>
        </div>
        <div className="infrastructure-images">
          <img
            src={`${process.env.PUBLIC_URL}/img/infra1.jpg`}
            alt="Large Courtyard"
            className="infra-image"
          />
          <img
            src={`${process.env.PUBLIC_URL}/img/infra2.jpg`}
            alt="Tiny Tots Block"
            className="infra-image"
          />
        </div>
      </div>
    </div>
  );
}

export default Infrastructure;