import React from "react";
import "./GrievanceRedressalCommittee.css"; // Updated CSS for styling

function GrievanceRedressalCommittee() {
  const committeeMembers = [
    {
      sNo: 1,
      name: "Mrs. Jayashree Narayanan",
      designation: "Principal",
      representing: "Principal",
      position: "President",
      contact: "8056138234",
      email: "jayashree310169@gmail.com",
    },
    {
      sNo: 2,
      name: "Mrs. S. Arulmozhi",
      designation: "HOD Tamil",
      representing: "Pre Primary Classes",
      position: "Member",
      contact: "9444451191",
      email: "arulmozhis726@gmail.com",
    },
    {
      sNo: 3,
      name: "Mrs. Devikala",
      designation: "Coordinator KG Sec",
      representing: "Primary Classes",
      position: "Member",
      contact: "9940385587",
      email: "lalasi577@gmail.com",
    },
    {
      sNo: 4,
      name: "Mrs. V. Bhuvaneswari",
      designation: "HOD Science",
      representing: "Middle School",
      position: "Member",
      contact: "9841420500",
      email: "bhuvanav1971@gmail.com",
    },
    {
      sNo: 5,
      name: "Mrs. Rajes Ashok",
      designation: "HOD English",
      representing: "High School",
      position: "Member",
      contact: "9940348351",
      email: "rajesashok137@gmail.com",
    },
    {
      sNo: 6,
      name: "Mrs. Lakshmi V",
      designation: "Vice Principal",
      representing: "Sr. Sec. School",
      position: "Member",
      contact: "9677017318",
      email: "lakshmi.varadhan@gmail.com",
    },
    {
      sNo: 7,
      name: "Mr. P. Sivasankaran",
      designation: "Transport Manager",
      representing: "Non-Teaching Staff",
      position: "Member",
      contact: "9952703424",
      email: "psivasankaran1996@gmail.com",
    },
  ];

  return (
    <div className="grievance-section">
      {/* Header Section */}
      <header className="grievance-header">
        <h1 className="grievance-title">Grievance Redressal Committee</h1>
        <div className="grievance-details">
          <p>Sri R.M. Jain Vidhyashram Sr. Secondary School</p>
          <p>V.M. Nagar, Tiruvallur 602 001</p>
          <p>Formation Date: 9th September, 2022</p>
          <p>
            The Committee was formed as per Office Note No. SRMVU / O.N.
            No.006/2022-23 dated 22nd July, 2022
          </p>
        </div>
      </header>

      {/* Table Section */}
      <table className="grievance-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Name</th>
            <th>Designation</th>
            <th>Representing</th>
            <th>Position</th>
            <th>Contact No</th>
            <th>Email ID</th>
          </tr>
        </thead>
        <tbody>
          {committeeMembers.map((member) => (
            <tr key={member.sNo}>
              <td>{member.sNo}</td>
              <td>{member.name}</td>
              <td>{member.designation}</td>
              <td>{member.representing}</td>
              <td>{member.position}</td>
              <td>{member.contact}</td>
              <td>{member.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <footer className="grievance-footer">
        <div className="originator">
          <p>Originated:</p>
          <p>Jayashree Narayanan</p>
          <p>Principal, Secretary SMC</p>
        </div>
        <div className="approver">
          <p>Approved:</p>
          <p>P. Kishore Kumar</p>
          <p>Director, Chairman SMC</p>
        </div>
      </footer>
    </div>
  );
}

export default GrievanceRedressalCommittee;