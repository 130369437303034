import React from "react";
import Slider from "react-slick";
import "./CarouselPage.css"; // Custom CSS for additional styling
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function CarouselPage() {
  // Slider settings
  const settings = {
    dots: true, // Enables dots at the bottom for navigation
    infinite: true, // Infinite looping of slides
    speed: 500, // Transition speed (milliseconds)
    slidesToShow: 3, // Number of slides to show at a time
    slidesToScroll: 1, // Slides scrolled at a time
    autoplay: true, // Automatically play slides
    autoplaySpeed: 3000, // Speed of autoplay (milliseconds)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Example items for the carousel
  const slides = [
    {
      id: 1,
      image: `${process.env.PUBLIC_URL}/img/slide1.jpg`,
      title: "Slide 1",
      description: "Description for slide 1",
    },
    {
      id: 2,
      image: `${process.env.PUBLIC_URL}/img/slide2.jpg`,
      title: "Slide 2",
      description: "Description for slide 2",
    },
    {
      id: 3,
      image: `${process.env.PUBLIC_URL}/img/slide3.jpg`,
      title: "Slide 3",
      description: "Description for slide 3",
    },
    {
      id: 4,
      image: `${process.env.PUBLIC_URL}/img/slide4.jpg`,
      title: "Slide 4",
      description: "Description for slide 4",
    },
  ];

  return (
    <div className="carousel-page">
      <h1 className="carousel-title">Glimpses from the Vibrant Classrooms & Big Playgrounds</h1>
      <h4>We ensure our students hone their skills in the best learning environment.</h4>
      <Slider {...settings}>
        {slides.map((slide) => (
          <div className="carousel-slide" key={slide.id}>
            <img src={slide.image} alt={slide.title} className="slide-image" />
            <h3 className="slide-title">{slide.title}</h3>
            <p className="slide-description">{slide.description}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default CarouselPage;