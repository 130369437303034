import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import "./OurObjectives.css";

function OurObjectives() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="objectives-section">
      {/* Header Section */}
      <header className="header">
        <h1 className="header-title">Our Objectives</h1>
        <div className="header-icons">
          🎓 📖 ✏️ 🌟 🧑‍🏫
        </div>
      </header>

      {/* Value-Based Education Section */}
      <div className="value-based-education" data-aos="fade-in">
        <div className="value-image">
          <img
            src={`${process.env.PUBLIC_URL}/img/obj1.jpg`}
            alt="Value-Based Education"
            className="value-image-element"
          />
        </div>
        <div className="value-text">
          <h2><span>📖</span> Value-Based Education</h2>
          <p>
            To create an environment that encourages freedom of thought, while inculcating self-discipline, 
            punctuality, fair play, and industry.
          </p>
        </div>
      </div>

      {/* Academic Excellence Section */}
      <div className="academic-exhibition" data-aos="fade-in">
        <div className="exhibition-text">
          <h2><span>🏫</span> Academic Excellence Through Exhibitions</h2>
          <p>
            The students actively participate in exhibitions where they showcase 
            creativity and innovation through their models and projects. This fosters 
            hands-on learning, teamwork, and critical thinking skills, aligning with 
            our mission to empower future leaders.
          </p>
        </div>
        <div className="exhibition-image">
          <img
            src={`${process.env.PUBLIC_URL}/img/obj2.jpg`}
            alt="Academic Exhibition"
            className="exhibition-image-element"
          />
        </div>
      </div>
{/* Co-Curricular Activities Section */}
<div className="co-curricular" data-aos="fade-in">
        <div className="co-curricular-image">
          <img
            src={`${process.env.PUBLIC_URL}/img/obj3.jpg`}
            alt="Co-Curricular Activities"
            className="co-curricular-image-element"
          />
        </div>
        <div className="co-curricular-text">
          <h2><span>⚽</span> Co-Curricular Activities</h2>
          <p>
            To provide our students with opportunities to experience and appreciate a broad range 
            of creative and performing arts, physical activities, and sports. These activities 
            imbibe team spirit and contribute to the holistic growth and development of the child, 
            finding balance in developing heart, mind, and body.
          </p>
        </div>
      </div>

      {/* Teaching Philosophy Section */}
      <div className="teaching-philosophy" data-aos="fade-in">
       
        <div className="teaching-text">
          <h2><span>🧑‍🏫</span> Teaching Philosophy</h2>
          <p>
            To constantly evolve our teaching strategies, incorporating new methodologies 
            and technologies to enable diverse learning styles and aligning those with advanced skills.
          </p>
        </div>
        <div className="teaching-image">
          <img
            src={`${process.env.PUBLIC_URL}/img/obj4.jpg`}
            alt="Teaching Philosophy"
            className="teaching-image-element"
          />
        </div>
      </div>

        {/* Community Service Section */}
      <div className="community-service" data-aos="fade-in">
        <div className="community-image">
          <img
            src={`${process.env.PUBLIC_URL}/img/obj5.jpeg`}
            alt="Community Service"
            className="community-image-element"
          />
        </div>
        <div className="community-text">
          <h2><span>🌱</span> Community Service</h2>
          <p>
            To learn about social, humanitarian, and environmental issues, and give our students the opportunity 
            to engage with local communities and service organizations. They work with these communities to contribute 
            to addressing their concerns, fostering a sense of self-awareness and responsibility for the world we all belong to.
          </p>
        </div>
      </div>


        {/* Individual Attention Section */}
      <div className="individual-attention" data-aos="fade-in">
        
        <div className="individual-text">
          <h2><span>👩‍🏫</span> Individual Attention</h2>
          <p>
            Our teaching approach prioritizes individual attention to students, enabling personalized learning experiences 
            that cater to their unique strengths and areas of improvement. Teachers foster one-on-one interactions, ensuring 
            students receive the guidance they need to excel academically and personally.
          </p>
        </div>
        <div className="individual-image">
          <img
            src={`${process.env.PUBLIC_URL}/img/obj6.jpg`}
            alt="Individual Attention"
            className="individual-image-element"
          />
        </div>
        </div>

        <div className="multicultural" data-aos="fade-up">
        <div className="multicultural-image">
          <img
            src={`${process.env.PUBLIC_URL}/img/obj7.jpg`}
            alt="Multicultural Dance Performance"
            className="multicultural-image-element"
          />
        </div>
        <div className="multicultural-text">
          <h2><span>👨‍👩‍👧</span> Multicultural</h2>
          <p>
            To embrace diversity and foster respect for all cultures and creeds, by creating a multicultural and secular ambience.
          </p>
        </div>
        
      </div>



    </div>
  );
}

export default OurObjectives;