import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS for animations
import "./Library.css"; // Import CSS for styling

function Library() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="library-section">
      <header className="library-header" data-aos="fade-up">
        <h1 className="library-title">Library</h1>
      </header>
      <div className="library-content" data-aos="fade-down">
        <div className="library-description">
          <p>
            The Library is housed with a well-furnished spacious reading hall. The library atmosphere encourages 
            students to spend fruitful time in this storehouse of knowledge. It houses over 17,000 books for reading 
            and reference, both for students and staff. The library is updated every year with books as per the 
            requirements of the students and teachers.
          </p>
        </div>
        <div className="library-images">
          <img
            src={`${process.env.PUBLIC_URL}/img/library1.jpg`}
            alt="Library Reading Hall"
            className="library-image"
          />
          <img
            src={`${process.env.PUBLIC_URL}/img/library2.jpg`}
            alt="Bookshelves and Students"
            className="library-image"
          />
        </div>
      </div>
    </div>
  );
}

export default Library;