import React, { useState, useEffect } from "react";
import "../components/Footer.css"

function Footer() {
  const [showButton, setShowButton] = useState(false);

  // Handle scroll event to toggle button visibility
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) { // Show button after scrolling 200px
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scroll to the top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling effect
    });
  };

  return (
    <>
      {/* Footer Section */}
      <footer
        className="container-fluid bg-dark text-light py-5"
        style={{
          position: "relative", // Ensure footer stays in normal document flow
          fontSize: "0.9rem",
          borderTop: "2px solid #555", // Highlight separation from content above
        }}
      >
        <div className="row">
          {/* Left Column: Contact Information */}
          <div className="col-md-4">
            <h5 className="text-uppercase fw-bold">Contact Information</h5>
            <p style={{ lineHeight: "1.8" }}>
              <strong>Address:</strong> <br />
              Sri R.M. Jain Vidyasharam Senior Secondary School, <br />
              Tiruvallur, 602001. <br />
              <strong>Phone:</strong> <br />
              044-2766 0451 / 9363592443 <br />
              <strong>Email:</strong> <br />
              info@rmjainschools.org, admission@rmjainschools.org <br />
              <strong>Website:</strong> <br />
              <a
                href="https://www.srmjy.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-light"
              >
                www.srmjy.com
              </a>
            </p>
          </div>

          {/* Middle Column: Quick Links */}
          <div className="col-md-4">
            <h5 className="text-uppercase fw-bold">Quick Links</h5>
            <ul className="list-unstyled" style={{ lineHeight: "1.8" }}>
              <li>
                <a href="/about-us" className="text-light text-decoration-none">
                  About Us
                </a>
              </li>
              <li>
                <a href="/our-schools" className="text-light text-decoration-none">
                  Our Schools
                </a>
              </li>
              <li>
                <a href="/transport" className="text-light text-decoration-none">
                  Transport
                </a>
              </li>
              <li>
                <a href="/rules" className="text-light text-decoration-none">
                  Rules and Regulations
                </a>
              </li>
              <li>
                <a href="/contact-us" className="text-light text-decoration-none">
                  Contact Us
                </a>
              </li>
              <li>
                <a href="/login" className="text-light text-decoration-none">
                  Login
                </a>
              </li>
            </ul>
          </div>

          {/* Right Column: Enquiry Form */}
          <div className="col-md-4">
            <h5 className="text-uppercase fw-bold">Admission Enquiry</h5>
            <form>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your Name"
                  style={{
                    borderRadius: "5px",
                    padding: "10px",
                    border: "1px solid #ccc",
                  }}
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone Number"
                  style={{
                    borderRadius: "5px",
                    padding: "10px",
                    border: "1px solid #ccc",
                  }}
                />
              </div>
              <div className="mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  style={{
                    borderRadius: "5px",
                    padding: "10px",
                    border: "1px solid #ccc",
                  }}
                />
              </div>
              <div className="mb-3">
                <select
                  className="form-select"
                  style={{
                    borderRadius: "5px",
                    padding: "10px",
                    border: "1px solid #ccc",
                  }}
                >
                  <option>Select Grade/Class</option>
                  <option value="LKG">LKG</option>
                  <option value="UKG">UKG</option>
                  <option value="1">1st Grade</option>
                  <option value="2">2nd Grade</option>
                  <option value="3">3rd Grade</option>
                  <option value="4">4th Grade</option>
                  <option value="5">5th Grade</option>
                </select>
              </div>
              <div className="mb-3">
                <textarea
                  className="form-control"
                  rows="3"
                  placeholder="Additional Information"
                  style={{
                    borderRadius: "5px",
                    padding: "10px",
                    border: "1px solid #ccc",
                  }}
                ></textarea>
              </div>
              <button
                type="submit"
                className="btn btn-primary w-100"
                style={{
                  backgroundColor: "#800080",
                  color: "#FFFFFF",
                  borderRadius: "50px",
                  padding: "10px 0",
                  fontWeight: "bold",
                }}
              >
                Submit
              </button>
            </form>
          </div>
          {/* Copyright Section */}
  <div
    className="text-center mt-4"
    style={{
      borderTop: "1px solid #555",
      paddingTop: "15px",
      color: "#888",
      fontSize: "0.8rem",
    }}
  >
    © Copyright 2025 Sri RM Jain Group of Schools | Powered by
    <a
      href="https://ffssoft.com"
      target="_blank"
      rel="noopener noreferrer"
      className="text-light text-decoration-none"
      style={{ marginLeft: "5px" }}
    >
      EASY
    </a>
  </div>
        </div>
      </footer>

      {/* Back to Top Button */}
      {showButton && (
        <button
          onClick={scrollToTop}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#800080",
            color: "#FFFFFF",
            border: "none",
            borderRadius: "50%",
            width: "50px",
            height: "50px",
            fontSize: "1.5rem",
            cursor: "pointer",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
          }}
          title="Back to Top"
        >
          ↑
        </button>
      )}
    </>
  );
}

export default Footer;