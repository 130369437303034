import React from "react";
import "./SexualHarassmentCommittee.css"; // Import the CSS file for styling

function SexualHarassmentCommittee() {
  const committeeMembers = [
    {
      sNo: 1,
      name: "R.Devikala",
      designation: "President",
      profession: "HOD KG Dept, SRMIV",
      email: "kala31577@gmail.com",
      mobile: "9940355857",
    },
    {
      sNo: 2,
      name: "V.Bhuvaneswari",
      designation: "Female Rep 1",
      profession: "HOD Eng Dept, SRMIV",
      email: "bhuvav1971@gmail.com",
      mobile: "9841742600",
    },
    {
      sNo: 3,
      name: "Meenakshi B",
      designation: "Female Rep 2",
      profession: "P.E.T. Teacher, SRMIV",
      email: "meenakshisudhakar1013@gmail.com",
      mobile: "9940215444",
    },
    {
      sNo: 4,
      name: "K RameshBabu",
      designation: "Male Rep 1",
      profession: "HOD P.E.T Dept, SRMIV",
      email: "rameshbabu.kasinathan@gmail.com",
      mobile: "9943150332",
    },
    {
      sNo: 5,
      name: "J. Padmanaban",
      designation: "Male Rep 2",
      profession: "Male Rep 2",
      email: "padmanji23@gmail.com",
      mobile: "9244909455",
    },
    {
      sNo: 6,
      name: "Dr.D Sivaganesh MBBS,DCH,DNB(Paeds)",
      designation: "Doctor",
      profession: "Doctor, GH Tiruvallur",
      email: "ganeshsivadr@gmail.com",
      mobile: "9865102719",
    },
    {
      sNo: 7,
      name: "R Janath Kumar",
      designation: "Advocate",
      profession: "Advocate",
      email: "janathkumar.kumar1711@gmail.com",
      mobile: "8754628840",
    },
    {
      sNo: 8,
      name: "Velu V S",
      designation: "Advocate",
      profession: "Advocate",
      email: "vsvelu.advocate@gmail.com",
      mobile: "8870622236",
    },
  ];

  return (
    <div className="committee-section">
      <header className="committee-header">
        <h1 className="committee-title">Sexual Harassment Committee</h1>
      </header>
      <table className="committee-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Name</th>
            <th>Designation</th>
            <th>Profession</th>
            <th>Email ID</th>
            <th>Mobile Number</th>
          </tr>
        </thead>
        <tbody>
          {committeeMembers.map((member) => (
            <tr key={member.sNo}>
              <td>{member.sNo}</td>
              <td>{member.name}</td>
              <td>{member.designation}</td>
              <td>{member.profession}</td>
              <td>{member.email}</td>
              <td>{member.mobile}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SexualHarassmentCommittee;