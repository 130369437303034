import React,{useState} from "react";
import { Routes, Route } from "react-router-dom"; // Removed BrowserRouter as Router
import Homepage from "./HomePage/HomePage"; // Vidhyashram-specific Homepage
import Header from "./components/VidhyashramHeader/Header";
import AddEvent from "./components/Notification/EventPage/AddEvent"; // Add Event Page
import EditEvent from "./components/Notification/EventPage/EditEvent"; // Edit Event Page
import OurJourneySection from "./components/Aboutus/OurJourney/OurJourneySection";
import OurObjectives from "./components/Aboutus/OurObjectiveSection/OurObjecticeSection";
import SexualHarassmentCommittee from "./components/Aboutus/SexualHarassmentCommittee/SexualHarassmentCommittee";
import StudentsCareCell from "./components/Aboutus/StudentCareCell/StudentCareCell";
import GrievanceRedressalCommittee from "./components/Aboutus/GrievanceRedressalCommittee/GrievanceRedressalCommittee";
import Infrastructure from "./components/Facilities/Infrastructure/Infrastructure";
import Library from "./components/Facilities/Library/Library";
import Gallery from "./components/Gallery/Gallery";
import Lab from "./components/Facilities/Lab/Lab";
import BadmintonCourt from "./components/Facilities/BadmintonCourt/BadmintonCourt";
import SmartBoard from "./components/Facilities/SmartBoard/SmartBoard";
import SchoolGround from "./components/Facilities/SchoolGround/SchoolGround";
import Transport from "./components/Facilities/Transport/Transport";
import Curriculum from "./components/Curriculum/Curriculum";
import Achievements from "./components/Acheivements/Acheivements";
import RulesRegulations from "./components/RulesRegulations/RulesRegulations";
import ScoutsGuides from "./components/SocialCommunityServices/ScoutesGuides/ScoutesGuides";
import Karuna from "./components/SocialCommunityServices/ScoutesGuides/KarunaClub/Karuna";
import EcoClub from "./components/SocialCommunityServices/ScoutesGuides/EcoClub/EcoClub";





function VidhyashramApp() {
  const [events, setEvents] = useState([]); // State to hold events

  // Function to handle adding a new event
  const addEvent = (newEvent) => {
    setEvents((prevEvents) => [...prevEvents, newEvent]); // Update state with the new event
    console.log("Updated Events:", events);
  };

  return (
    <div>
      {/* Header visible only on Vidhyashram pages */}
    <Header />
      <Routes>
        <Route path="/" element={<Homepage />} />
        {/* Event Management */}
        <Route path="/event/add" element={<AddEvent addEvent={addEvent} />} />
        <Route path="/event/edit/:id" element={<EditEvent />} />
        <Route path="/about-us/ourjourney" element={<OurJourneySection />} />
        <Route path="/about-us/our-objectives" element={<OurObjectives />} />
        <Route path="/about-us/sexual-harassment-committee" element={<SexualHarassmentCommittee />} />
        <Route path="/about-us/student-care-cell" element={<StudentsCareCell />}/>
        <Route path="/about-us/grievance-redressal-committee" element={<GrievanceRedressalCommittee  />}/>
        <Route path="/facilities/Infrastructure" element={<Infrastructure />} />
        <Route path="/facilities/Library" element={<Library />} />
        <Route path="/facilities/lab" element={<Lab />} />
        <Route path="/facilities/badminton" element={<BadmintonCourt/>}/>
        <Route path="/facilities/smartboard" element={<SmartBoard/>}/>
        <Route path="/facilities/schoolground" element={<SchoolGround/>}/>
        <Route path="/facilities/transport" element={<Transport/>}/>
        <Route path="/curriculum" element={<Curriculum />} />
        <Route path="/Achievements" element={<Achievements />} />
        <Route path="/rules-regulations" element={<RulesRegulations />} />
        <Route path="/community-services/scouts" element={<ScoutsGuides />} />
        <Route path="/community-services/karuna" element={<Karuna />} />
        <Route path="/community-services/Eco" element={<EcoClub />} />








        <Route path="/gallery" element={<Gallery />} />


        {/* Add more Vidhyashram-specific routes as needed */}
      </Routes>
    </div>
  );
}

export default VidhyashramApp;
