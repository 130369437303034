import React from "react";
import "./ContactUs.css"; // Custom CSS for styling

function ContactUs() {
  const schools = [
    {
      name: "Sri R.M. Jain Vidyashram Senior Secondary School",
      address: "4WJ6+Q6H, JN Rd, Hariram Nagar, V.M Nagar, Kakkalur, Tamil Nadu 602001",
      phone: ["044 2766 0451", "9363592443"],
      email: ["info@rmjainschools.org","admission@rmjainschools.org"],
      mapSrc:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.482357225409!2d79.90804017321292!3d13.13194561134634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5290239ef09501%3A0xc3805b29c00a7ba4!2sSri%20R.M.Jain%20Vidhyashram!5e0!3m2!1sen!2sin!4v1742982199842!5m2!1sen!2sin",
    },
    {
      name: "Sri R.M. Jain Matriculation School",
      address: "4WJ6+Q6H, JN Rd, Hariram Nagar, V.M Nagar, Kakkalur, Tamil Nadu 602001",
      phone: ["044 2766 0551", "9597209414"],
      email: ["srmjvm2017@gmail.com"],
      mapSrc:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.482357225409!2d79.90804017321292!3d13.13194561134634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5290239ef09501%3A0xc3805b29c00a7ba4!2sSri%20R.M.Jain%20Matriculation%20School!5e0!3m2!1sen!2sin!4v1742982199842!5m2!1sen!2sin", // Replace with Matriculation school map link
    },
  ];

  return (
    <div className="contact-us-container">
      <header className="contact-header">
        <h1 className="contact-title">Contact Us</h1>
      </header>

      {schools.map((school, index) => (
        <section key={index} className="school-section">
          <h2>{school.name}</h2>
          <p>
            <strong>Address:</strong> {school.address}
          </p>
          <p>
            <strong>Phone:</strong>{" "}
            {school.phone.map((phone, i) => (
              <a key={i} href={`tel:${phone}`} className="phone-link">
                {phone}
              </a>
            )).reduce((prev, curr) => [prev, " / ", curr])} {/* Add "/" between numbers */}
          </p>
          <p>
            <strong>Email:</strong>{" "}
            {school.email.map((e, i) => (
              <a key={i} href={`mailto:${e}`} className="email-link">
                {e}
              </a>
            ))}
          </p>
          <div className="map-container">
            <iframe
              src={school.mapSrc}
              title={`Map of ${school.name}`}
              width="100%"
              height="450"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </section>
      ))}
    </div>
  );
}

export default ContactUs;
