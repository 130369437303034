import React from "react";

import HeroSection from "../HeroSection/HeroSection"
import "./Home.css";
import CardSection from "../CardSections/CardSection";
import NotificationPage from "../NotificationPage/NotificationPage";
import WhyChooseUs from "../WhyChoose/WhyChooseUs";
import Glimpse from "../Glimpse/Glimpse";
import SrmjvAims from "../SrmjvAims/SrmjvAims";

// import Notification from "../components/Notification/Notification";
// import CarouselPage from "../components/CarouselPage";
// import WhySRMJV from "../components/Notification/WhySRMJV/WhySRMJV";
// import EventPage from "../components/Notification/EventPage/EventPage";
// import { Link } from "react-router-dom";
// import TinyTotsBlock from "../components/TinyTots/TinyTots";
// import LibraryClubSection from "../components/LibraryClubSection/LibraryClubSection";
// import AlumniSection from "../components/AlumniSection/AlumniSection";
// import AdmissionsSection from "../components/AdmissionSection/AdmissionsSection";
// import FAQSection from "../components/FAQSection/FAQSection";

function Home() {
  return (
    <div className="homepage">
      
      <HeroSection />
      <CardSection />
      <NotificationPage />
      <WhyChooseUs />
      <Glimpse />
      <SrmjvAims />
      {/* <Notification />
      <CarouselPage />
      <WhySRMJV />
      <EventPage />
      <div className="event-management">
        <Link to="/event/add" className="btn btn-primary">Add Event</Link>
        <Link to="/event/edit/1" className="btn btn-secondary">Edit Event</Link>
      </div>
      <TinyTotsBlock />
      <LibraryClubSection />
      <AlumniSection />
      <AdmissionsSection />
      <FAQSection /> */}
 
    </div>
  );
}

export default Home;