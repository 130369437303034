import React, { useEffect, useState } from 'react';
import supabase from '../../../supabaseClient';
import './NotificationPage.css';
import MediaLibrary from './MediaManagement/MediaLibrary';
import MediaManagementPage from './MediaManagement/MediaManagement';

function NotificationPage() {
  const [mediaLibrary, setMediaLibrary] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch media items from Supabase
  useEffect(() => {
    const fetchMediaLibrary = async () => {
      const { data, error } = await supabase.from('media_library').select('*');
      if (error) {
        console.error('Error fetching data:', error.message);
      } else {
        setMediaLibrary(data);
      }
      setLoading(false);
    };

    fetchMediaLibrary();
  }, []);

 

  if (loading) return <p>Loading...</p>;

  return (
    <div className="notification-page">
      
      {mediaLibrary.length > 0 ? (
       <> 
        {/* <MediaManagementPage /> */}
        <MediaLibrary /></>
      ) : (
        <p>No media available. Please add some media links!</p>
      )}
    </div>
  );
}

export default NotificationPage;