import React from "react";
import "./ParentPortal.css"; // Custom CSS for styling

function ParentPortal() {
  return (
    <div className="parent-portal-container">
      {/* Header Section */}
      <header className="portal-header">
        <h1 className="portal-title">Parent Portal</h1>
      </header>

      {/* PDF Viewer Section */}
      <section className="pdf-viewer-section">
        <h2>Online Fees Payment Manual</h2>
        <p>
          Please follow the instructions in the manual below to complete your online fees payment.
        </p>
        <div className="pdf-container">
          <iframe
            src={`${process.env.PUBLIC_URL}/img/online-fees.pdf`}
            title="Online Fees Manual"
            width="100%"
            height="600px"
            className="pdf-viewer"
          ></iframe>
        </div>
      </section>
    </div>
  );
}

export default ParentPortal;
