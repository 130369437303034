import React,{useEffect} from "react";
import "../index.css";

function AchievementsHighlight() {
  useEffect(() => {
    // Access all video elements in the carousel
    const videos = document.querySelectorAll(".video-player");

    videos.forEach((video) => {
      video.addEventListener("loadedmetadata", () => {
        // Set video to start from the 2-second mark
        video.currentTime = 2;
      });
    });
  }, []);
  return (
    <>
    {/* Achievements Photo Grid */}
<div
  className="container mt-5"
  data-aos="fade-up" /* AOS Animation for the section */
>
  {/* Heading */}
  <h2
    className="text-center mb-4"
    style={{ color: "#5f055f", fontWeight: "bold" }}
  >
    Achievements Highlight
  </h2>

  {/* Photo Grid */}
  <div className="row text-center justify-content-center">
    {/* Photo 1 */}
    <div className="col-md-3">
      <img
        src={`${process.env.PUBLIC_URL}/img/photo1.jpg`} // Replace with actual file path
        alt="Achievement Highlight 1"
        className="img-fluid"
        style={{
          width: "100%",
          height: "300px", // Ensures all photos have the same height
          objectFit: "cover", // Ensures the image maintains its proportions
          borderRadius: "10px", // Optional rounded corners
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Adds a shadow for a polished look
        }}
      />
      <p style={{ fontWeight: "bold", color: "#535050", marginTop: "10px" }}>
        Achievement Highlight 1
      </p>
    </div>

    {/* Photo 2 */}
    <div className="col-md-3">
      <img
        src={`${process.env.PUBLIC_URL}/img/photo2.jpeg`} // Replace with actual file path
        alt="Achievement Highlight 2"
        className="img-fluid"
        style={{
          width: "100%",
          height: "300px",
          objectFit: "cover",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      />
      <p style={{ fontWeight: "bold", color: "#535050", marginTop: "10px" }}>
        Achievement Highlight 2
      </p>
    </div>

    {/* Photo 3 */}
    <div className="col-md-3">
      <img
        src={`${process.env.PUBLIC_URL}/img/photo3.jpg`} // Replace with actual file path
        alt="Achievement Highlight 3"
        className="img-fluid"
        style={{
          width: "100%",
          height: "300px",
          objectFit: "cover",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      />
      <p style={{ fontWeight: "bold", color: "#535050", marginTop: "10px" }}>
        Achievement Highlight 3
      </p>
    </div>

    {/* Photo 4 */}
    <div className="col-md-3">
      <img
        src={`${process.env.PUBLIC_URL}/img/photo4.jpg`} // Replace with actual file path
        alt="Achievement Highlight 4"
        className="img-fluid"
        style={{
          width: "100%",
          height: "300px",
          objectFit: "cover",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      />
      <p style={{ fontWeight: "bold", color: "#535050", marginTop: "10px" }}>
        Achievement Highlight 4
      </p>
    </div>
  </div>
</div>
{/* Parents' Testimonial Video Carousel */}
<div
  className="container mt-5"
  data-aos="fade-up" /* AOS Animation for smooth appearance */
>
  {/* Heading */}
  <h4
    className="text-center"
    style={{ color: "#5f055f", fontWeight: "bold" }}
  >
    Our Parent's 
  </h4>
  <h2
    className="text-center mb-4"
    style={{ color: "#5f055f", fontWeight: "bold" }}
  >
    Testimonial
  </h2>

  {/* Bootstrap Carousel */}
  <div
    id="testimonialCarousel"
    className="carousel slide"
    data-bs-ride="false" /* Disables auto-slide */
  >
    <div className="carousel-inner text-center">
      {/* Slide 1 */}
      <div className="carousel-item active">
        <video
          className="w-100 video-player"
          muted
            controls="true"
          autoplay /* Starts playing automatically */
          style={{
            borderRadius: "10px",
            maxHeight: "500px",
          }}
        >
          <source
            src={`${process.env.PUBLIC_URL}/img/testimonial/testimonial-1.mp4`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Slide 2 */}
      <div className="carousel-item">
        <video
          className="w-100 video-player"
          muted
          autoplay
          controls="true"
          style={{
            borderRadius: "10px",
            maxHeight: "500px",
          }}
        >
          <source
            src={`${process.env.PUBLIC_URL}/img/testimonial/testimonial-2.mp4`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Slide 3 */}
      <div className="carousel-item">
        <video
          className="w-100 video-player"
          muted
            controls="true"
          autoplay
          style={{
            borderRadius: "10px",
            maxHeight: "500px",
          }}
        >
          <source
            src={`${process.env.PUBLIC_URL}/img/testimonial/testimonial-3.mp4`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Slide 4 */}
      <div className="carousel-item">
        <video
          className="w-100 video-player"
          muted
            controls="true"
          autoplay
          style={{
            borderRadius: "10px",
            maxHeight: "500px",
          }}
        >
          <source
            src={`${process.env.PUBLIC_URL}/img/testimonial/testimonial-4.mp4`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>

    {/* Carousel Controls */}
    <div
      className="text-center mt-3"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
      }}
    >
      <button
        className="btn btn-secondary"
        type="button"
        data-bs-target="#testimonialCarousel"
        data-bs-slide="prev"
        style={{
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        &lt;
      </button>

      <button
        className="btn btn-secondary"
        type="button"
        data-bs-target="#testimonialCarousel"
        data-bs-slide="next"
        style={{
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        &gt;
      </button>
    </div>
  </div>
</div>


{/* Admissions Section */}
<div
  className="container mt-5"
  data-aos="fade-up" /* Adds a smooth animation effect */
>
  <div className="row align-items-center">
    {/* Left Column: Content */}
    <div className="col-md-8">
      <h2 style={{ color: "#5f055f", fontWeight: "bold" }}>
        Admissions Open Across All Locations for 2025-2026
      </h2>
      <p style={{ fontSize: "1.1rem", color: "#535050", lineHeight: "1.8" }}>
        Step into excellence with SRMJV schools! Our admissions are now open
        for the academic year 2025-2026 across all our locations. We offer a
        nurturing environment focused on academic success, extracurricular
        enrichment, and character development to ensure holistic growth for
        every student.
      </p>
    </div>

    {/* Right Column: Apply Now Button */}
    <div className="col-md-4 text-center">
      <button
        className="btn btn-primary"
        style={{
          backgroundColor: "#800080", // Purple color for the button
          color: "#FFFFFF", // White text
          borderRadius: "50px", // Rounded edges
          padding: "10px 20px",
          fontSize: "1.2rem",
          fontWeight: "bold",
        }}
        onClick={() => {
          window.location.href = "/enquiry"; /* Redirects to another enquiry page */
        }}
      >
        Apply Now
      </button>
    </div>
  </div>
</div>

</>
  );
}

export default AchievementsHighlight;