import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import "./SmartBoard.css"; // Import CSS for styling

function SmartBoard() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="smart-board-section">
      <header className="smart-board-header" data-aos="fade-up">
        <h1 className="smart-board-title">Smart Boards</h1>
      </header>
      <div className="smart-board-content">
        <div className="smart-board-description" data-aos="fade-down">
          <p>
            Smart Boards are a technology-oriented initiative to enrich the learning 
            experience in the classroom. They provide tools and content for interactive 
            self-paced learning by students, as well as rich media presentations for teachers.
          </p>
        </div>
        <div className="smart-board-images" data-aos="fade-up">
          <img
            src={`${process.env.PUBLIC_URL}/img/sb1.jpeg`}
            alt="Classroom Smart Board Display"
            className="smart-board-image"
          />
          <img
            src={`${process.env.PUBLIC_URL}/img/sb2.jpeg`}
            alt="Teacher Using a Smart Board"
            className="smart-board-image"
          />
          <img
            src={`${process.env.PUBLIC_URL}/img/sb3.jpeg`}
            alt="Teacher Using a Smart Board"
            className="smart-board-image"
          />
          <img
            src={`${process.env.PUBLIC_URL}/img/sb4.jpeg`}
            alt="Teacher Using a Smart Board"
            className="smart-board-image"
          />
        </div>
      </div>
    </div>
  );
}

export default SmartBoard;