import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import "./Lab.css"; // Import Lab page CSS

function Lab() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="lab-section">
      <header className="lab-header" data-aos="fade-up">
        <h1 className="lab-title">Lab Facilities</h1>
      </header>
      <div className="lab-content">
        <div className="lab-description" data-aos="fade-down">
          <p>
            As technology evolves and new experiments are made in the fields of science, mathematics, and English, 
            our school provides state-of-the-art facilities for students to explore, investigate, and enhance their skills.
          </p>
          <p>
            Chemistry, physics, biology, and computer labs offer hands-on experiences, while the linguistics lab 
            enriches speaking and listening skills.
          </p>
        </div>
        <div className="lab-images" data-aos="fade-up">
          <img
            src={`${process.env.PUBLIC_URL}/img/lab/lab1.jpeg`}
            alt="Chemistry Lab"
            className="lab-image"
          />
          <img
            src={`${process.env.PUBLIC_URL}/img/lab/lab2.jpeg`}
            alt="Biology Lab"
            className="lab-image"
          />
          <img
            src={`${process.env.PUBLIC_URL}/img/lab/lab3.jpeg`}
            alt="Computer Lab"
            className="lab-image"
          />
          <img
            src={`${process.env.PUBLIC_URL}/img/lab/lab4.jpeg`}
            alt="Computer Lab"
            className="lab-image"
          />
          <img
            src={`${process.env.PUBLIC_URL}/img/lab/lab5.jpeg`}
            alt="Computer Lab"
            className="lab-image"
          />
          <img
            src={`${process.env.PUBLIC_URL}/img/lab/lab6.jpeg`}
            alt="Computer Lab"
            className="lab-image"
          />
          <img
            src={`${process.env.PUBLIC_URL}/img/lab/lab7.jpeg`}
            alt="Computer Lab"
            className="lab-image"
          />
          <img
            src={`${process.env.PUBLIC_URL}/img/lab/lab8.jpeg`}
            alt="Computer Lab"
            className="lab-image"
          />
          
        </div>
      </div>
    </div>
  );
}

export default Lab;