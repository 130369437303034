import React from "react";
import "./Administration.css"; // Custom CSS for styling

function Administration() {
  return (
    <div className="administration-container">
      {/* Header Section */}
      <header className="administration-header">
        <h1 className="administration-title">Administration</h1>
      </header>

      {/* Managing Trustee Section */}
      <section className="trustee-section">
        <div className="trustee-photo">
          <img
            src={`${process.env.PUBLIC_URL}/img/Managing-Trustee.jpg`}
            alt="Sri. M Bhikamchand Jain"
            className="trustee-image"
          />
        </div>
        <div className="trustee-message">
          <h2>Sri. M Bhikamchand Jain</h2>
          <h3>Managing Trustee</h3>
          <p>
            It is my pleasure to extend heartfelt greetings to the parents. As the
            Managing Trustee of Sri. R.M. Jain Group of schools, it is indeed a profound
            opportunity and distinct privilege to serve the noble cause of providing quality
            education to our children.
          </p>
          <p>
            SRMJV is dedicated in pursuit of academic excellence, encouraging students to
            become independent and perceptive thinkers, confident, enlightened learners, and
            socially responsible citizens.
          </p>
          <p>
            Through scholastic and co-scholastic activities, we are trying to fulfill our
            endeavor of helping students learn and enabling them to become the leaders of
            tomorrow.
          </p>
        </div>
      </section>

      {/* Trustees Section */}
      <section className="trustees-section">
        <h2>Our Trustees</h2>
        <ul className="trustees-list">
          <li>Mr. P. Padamchand Jain</li>
          <li>Mr. P. Kishore Kumar Jain</li>
          <li>Mr. P. Ajith Kumar Jain</li>
          <li>Mr. B. Roshan Kumar Jain</li>
          <li>Mr. B. Abinandan Jain</li>
        </ul>
        <div className="trustee-statement">
          <p>
            <strong>WE the Trustees</strong> endeavor to provide a welcoming, exciting, and
            safe environment for all with us. We are committed to give the best to each
            individual who walks through the portals of Sri.R.M.Jain Group of Schools, be it
            a student, a teacher, staff, or a parent.
          </p>
          <p>
            <strong>WE strongly believe</strong> that all this can be done with the trusted
            partner of the parent who is the backbone of the child’s growth. Our values of
            Excellence, Respect, Tolerance, Integrity, and Compassion are evident in the
            ways we work together. It’s our combined responsibility to encourage each child
            to be curious, creative, and challenging.
          </p>
        </div>
      </section>
    </div>
  );
}

export default Administration;
