import React, { useEffect, useState } from "react";
import supabase from "../../../../supabaseClient";
import "./MediaLibrary.css";

const MediaLibrary = () => {
  const [mediaItems, setMediaItems] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch data from Supabase
  useEffect(() => {
    const fetchData = async () => {
      // Fetch Media Library
      const { data: mediaData, error: mediaError } = await supabase
        .from("media_library")
        .select("*");
      if (mediaError) {
        console.error("Error fetching media items:", mediaError.message);
      } else {
        setMediaItems(mediaData);
      }

      // Fetch Notifications
      const { data: notificationsData, error: notificationsError } = await supabase
        .from("notifications")
        .select("*");
      if (notificationsError) {
        console.error("Error fetching notifications:", notificationsError.message);
      } else {
        setNotifications(notificationsData);
      }

      // Fetch Announcements
      const { data: announcementsData, error: announcementsError } = await supabase
        .from("announcements")
        .select("*");
      if (announcementsError) {
        console.error("Error fetching announcements:", announcementsError.message);
      } else {
        setAnnouncements(announcementsData);
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Display a loading message while fetching data
  }

  return (
    <div className="content-layout">
      {/* Notifications Section */}
      <div className="notifications-section">
        <h1 className="section-title">Notifications</h1>
        {notifications.length > 0 ? (
          <ul className="item-list">
            {notifications.map((notification) => (
              <li key={notification.id} className="item">
                <h3>{notification.title}</h3>
                <p>{notification.description}</p>
                <small>{new Date(notification.created_at).toLocaleDateString()}</small>
              </li>
            ))}
          </ul>
        ) : (
          <p>No notifications available.</p>
        )}
      </div>

      {/* Announcements Section */}
      <div className="announcements-section">
        <h1 className="section-title">Announcements</h1>
        {announcements.length > 0 ? (
          <ul className="item-list">
            {announcements.map((announcement) => (
              <li key={announcement.id} className="item">
                <h3>{announcement.title}</h3>
                <p>{announcement.content}</p>
                <small>{new Date(announcement.created_at).toLocaleDateString()}</small>
              </li>
            ))}
          </ul>
        ) : (
          <p>No announcements available.</p>
        )}
      </div>

      {/* Media Library Section */}
      <div className="media-library-section">
        <h1 className="section-title">Media Library</h1>
        <div className="media-scroller">
          {mediaItems.length > 0 ? (
            mediaItems.map((item) => (
              <div key={item.id} className="media-item">
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="media-link"
                >
                  <i className="fas fa-film media-icon"></i>
                  <span className="media-title">{item.title}</span>
                </a>
              </div>
            ))
          ) : (
            <p>No media items found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MediaLibrary;