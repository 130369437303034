import React, { useState } from "react";
import "../../index.css"; // Include your custom styles

function SwitchSchools() {
  const [showModal, setShowModal] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  // Open modal
  const openModal = () => {
    setIsClosing(false);
    setShowModal(true);
  };

  // Close modal with animation
  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setShowModal(false);
      setIsClosing(false);
    }, 400); // Match the CSS animation duration
  };

  return (
    <>
      {/* Sticky "Switch Schools" Button */}
      <div
        onClick={openModal}
        className="switch-schools-button"
        style={{
          position: "fixed",
          right: "0",
          top: "50%",
          transform: "translateY(-50%) rotate(180deg)",
          backgroundColor: "#800080",
          color: "#FFFFFF",
          writingMode: "vertical-rl",
          fontWeight: "bold",
          padding: "10px 20px",
          borderRadius: "0 5px 5px 0",
          cursor: "pointer",
          zIndex: 1000,
        }}
      >
        Switch Schools
      </div>

      {/* Modal */}
      {showModal && (
        <div
          className={`modal-overlay ${isClosing ? "slide-out" : ""}`}
          onClick={closeModal}
          role="dialog"
          aria-labelledby="modal-header"
        >
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
            aria-modal="true"
          >
            <h2 className="modal-header" id="modal-header">
              RMJ Group of Schools
            </h2>

            {/* School Links */}
            <div className="school-link">
              <a href="/vidhyashram">
                Sri R.M. Jain Vidyasharam Senior Secondary School (CBSE)
              </a>
            </div>
            <div className="school-link">
              <a href="/vidhyapeeth">
                Sri R.M. Jain Vidyapeeth Matric. Hr. Sec. School (Matric)
              </a>
            </div>

            {/* Close Button */}
            <button className="close-btn" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default SwitchSchools;