import React from "react";
import "./AdmissionsSection.css"; // Import custom CSS for styling

function AdmissionsSection() {
  return (
    <div className="admissions-section">
      {/* Left Side: Admission Process Details */}
      <div className="admissions-info">
        <h2 className="admissions-title">Admissions Open for 2024-2025</h2>
        <p className="admissions-description">
          SRMJV Schools are currently accepting admissions for the academic session 2024-2025.
        </p>
        <ul className="admissions-steps">
          <li>✔ Issue of Registration form (Walk-in) – 12th Oct 2024.</li>
          <li>✔ Fill the form giving all the details.</li>
          <li>✔ Submit the form attaching all supportive documents mentioned.</li>
          <li>
            ✔ You will be intimated about the selection procedure on the basis
            of the class opted for.
          </li>
        </ul>
      </div>

      {/* Right Side: Application Form */}
      <div className="admissions-form">
        <h3 className="form-title">Apply for Admission</h3>
        <form className="form-fields">
          <div className="form-group">
            <label htmlFor="first-name">First Name*</label>
            <input type="text" id="first-name" name="first-name" required />
          </div>
          <div className="form-group">
            <label htmlFor="phone-number">Phone Number*</label>
            <input type="tel" id="phone-number" name="phone-number" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email Address*</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div className="form-group">
            <label htmlFor="grade">Select Grade/Class*</label>
            <select id="grade" name="grade" required>
              <option value="">-- Select --</option>
              <option value="kg">Kindergarten</option>
              <option value="primary">Primary</option>
              <option value="middle">Middle School</option>
              <option value="high">High School</option>
            </select>
          </div>
          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default AdmissionsSection;