import React from "react";
import "./CardSection.css";

function CardSection() {
  return (
    <div className="card-section">
      <div className="card">
        <h5 className="card-title">Location</h5>
        <p className="card-text">Tiruvallur</p>
      </div>
      <div className="card">
        <h5 className="card-title">Curriculum</h5>
        <p className="card-text">CBSE</p>
      </div>
      <div className="card">
        <h5 className="card-title">Admission</h5>
        <p className="card-text">Admissions open for the Academic Year 2025–2026 for Pre.KG to VIII and XI Grade.</p>
      </div>
      <div className="card">
        <h5 className="card-title">Infrastructure</h5>
        <p className="card-text">State of the Art</p>
      </div>
      <div className="card">
        <h5 className="card-title">Specialization</h5>
        <p className="card-text">Project-Based Learning</p>
      </div>
    </div>
  );
}

export default CardSection;