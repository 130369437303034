import React from "react";

function WhyChooseUs() {
  return (
    <div className="container mt-5">
      <div className="row align-items-center">
        <h2
          className="mb-4"
          style={{
            color: "#5f055f",
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Why You Have to Choose SRMJV?
        </h2>
        {/* Image Section */}
        <div
          className="col-md-6"
          data-aos="fade-right" /* AOS Animation for the image section */
          style={{ textAlign: "center" }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/img/whychoose.jpg`} // Add the correct image here
            alt="Classroom Setting"
            className="img-fluid"
            style={{ borderRadius: "10px" }}
          />
        </div>

        {/* Content Section */}
        <div
          className="col-md-6"
          data-aos="fade-left" /* AOS Animation for content section */
          style={{ lineHeight: "1.8", color: "#535050" }}
        >
             {/* Graduation Cap Icon */}
             <i className="fas fa-graduation-cap fa-3x" style={{ marginRight: "10px", color: "#5f055f",marginBottom:"20px",
      backgroundImage: "linear-gradient(to bottom, purple, pink)",
      WebkitBackgroundClip: "text",
      backgroundClip: "text",color:"transparent" }}></i>
          <h4 style={{ fontWeight: "bold", color: "#5f055f", display: "flex", alignItems: "center" }}>
           
            Academics at SRMJV
          </h4>
          <p style={{ fontSize: "1.1rem" }}>
            Good academics are the foundation of any school, and our heritage of instilling academics
            goes back to our basic teaching principle: <strong>'We facilitate, We do not instruct.'</strong>
          </p>
          <p style={{ fontSize: "1.1rem" }}>
            Our teachers, through their continued guidance and support, enable us to unravel the potential
            in each individual to ignite their originality and nurture the zeal for achieving their goals.
          </p>
        </div>

     {/* Second Row - Sports Section */}
     <div className="row align-items-center">
        {/* Sports Content */}
        <div
          className="col-md-6"
          data-aos="fade-right" /* AOS Animation for sports content */
          style={{ lineHeight: "1.8", color: "#535050", marginTop:"30px" }}
        >
            <i className="fas fa-basketball-ball fa-4x" style={{ marginRight: "10px", color: "#5f055f",marginBottom:"20px",
      backgroundImage: "linear-gradient(to bottom, purple, pink)",
      WebkitBackgroundClip: "text",
      backgroundClip: "text", color:"transparent" }}></i>
          <h4 style={{ fontWeight: "bold", color: "#5f055f" }}>
            
            Our Sports
          </h4>
          <p style={{ fontSize: "1.1rem" }}>
            Sports facilitate the development of a peaceful mind, healthy body, sharp intellect, and social skills.
            Our motto is to encourage every student to participate in at least one sport, be it indoors or outdoors.
          </p>
          <p style={{ fontSize: "1.1rem" }}>
            Students who have a thirst for specializing in any particular sport are offered mentorship and guidance
            by our well-trained coaches and through special sports programs to achieve success in their chosen fields.
          </p>
        </div>

        {/* Sports Image Section */}
        <div
          className="col-md-6"
          data-aos="fade-left" /* AOS Animation for sports image */
          style={{ textAlign: "center",marginTop:"40px" }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/img/sports.jpg`} // Add the correct image path here
            alt="Indoor Sports Facility"
            className="img-fluid"
            style={{ borderRadius: "10px" }}
          />
        </div> 
      </div>

{/* Third Row - Multifaceted Development Section */}
<div className="row align-items-center">
        {/* Multifaceted Development Image Section */}
        <div
          className="col-md-6"
          data-aos="fade-right" /* AOS Animation for image section */
          style={{ textAlign: "center" }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/img/multifacted.jpg`} // Update with actual image path
            alt="Multifaceted Development"
            className="img-fluid"
            style={{ borderRadius: "10px" }}
          />
        </div>

        {/* Multifaceted Development Content Section */}
        <div
          className="col-md-6"
          data-aos="fade-left" /* AOS Animation for content section */
          style={{ lineHeight: "1.8", color: "#535050" }}
        >

            {/* Microphone Image */}
            <div style={{ textAlign: "left", marginBottom: "10px" }}>
                <img
                    src={`${process.env.PUBLIC_URL}/img/mic.png`} // Correct the path based on your project structure
                    alt="Microphone Icon"
                    style={{
                    width: "50px", // Adjust the size as needed
                    height: "50px",
                    }}
                />
</div>

          <h4 style={{ fontWeight: "bold", color: "#5f055f" }}>
            Multifaceted Development at SRMJV
          </h4>
          <p style={{ fontSize: "1.1rem" }}>
            Every child has a unique combination of varied intelligence. Thus, we encourage our students to participate
            in various co-curricular activities offered beyond classroom learning to unravel their own competencies.
          </p>
          <p style={{ fontSize: "1.1rem" }}>
            We strive to create a space where children can speak their minds, express their hearts, and explore their souls.
          </p>
        </div>

        <div
  className="row align-items-center"
  style={{ marginTop: "40px" }} // Optional spacing above this section
>
  

  {/* Beyond Schooling Content Section */}
  <div
    className="col-md-6"
    data-aos="fade-left" /* AOS Animation for content section */
    style={{ lineHeight: "1.8", color: "#535050" }}
  >
            {/* open book Image */}
            <div style={{ textAlign: "left", marginBottom: "10px" }}>
                <img
                    src={`${process.env.PUBLIC_URL}/img/open-book.png`} // Correct the path based on your project structure
                    alt="Microphone Icon"
                    style={{
                    width: "50px", // Adjust the size as needed
                    height: "50px",
                    }}
                />
            </div>

    <h4 style={{ fontWeight: "bold", color: "#5f055f" }}>
      Beyond Schooling at SRMJV
    </h4>
    <p style={{ fontSize: "1.1rem" }}>
      At SRMJV, education goes beyond the boundaries of traditional schooling. 
      We provide an environment where students are encouraged to engage in 
      practical, hands-on experiences that prepare them for real-world challenges.
    </p>
    <p style={{ fontSize: "1.1rem" }}>
      Our interactive learning programs, combined with a focus on community 
      engagement, allow students to explore and develop skills that nurture creativity, 
      teamwork, and innovation. This unique approach ensures that students 
      grow into well-rounded individuals ready to take on future challenges.
    </p>
  </div>
  {/* Beyond Schooling Image Section */}
  <div
    className="col-md-6"
    data-aos="fade-right" /* AOS Animation for image section */
    style={{ textAlign: "center" }}
  >
    <img
      src={`${process.env.PUBLIC_URL}/img/beyond.jpg`} // Replace with actual file path for the image
      alt="Beyond Schooling"
      className="img-fluid"
      style={{ borderRadius: "10px" }}
    />
  </div>
</div>
                {/* Vision and Mission Section */}
<div
  className="row align-items-center"
  style={{ marginTop: "40px" }} // Optional spacing above this section
>
  

  {/* Vision and Mission Content Section */}
  <div
    className="col-md-12"
    data-aos="fade-left" /* AOS Animation for content section */
    style={{ lineHeight: "1.8", color: "#535050" }}
  > 
    {/* open book Image */}
    <div style={{ textAlign: "center", marginBottom: "10px" }}>
                <img
                    src={`${process.env.PUBLIC_URL}/img/vision.png`} // Correct the path based on your project structure
                    alt="Microphone Icon"
                    style={{
                    width: "30px", // Adjust the size as needed
                    height: "30px",
                    }}
                />
            </div>

    <h4 style={{ fontWeight: "bold", color: "#5f055f", textAlign:"center" }}>
      Our Vision
    </h4>
    <p style={{ fontSize: "1.1rem" }}>
      To establish SRMJV as a world-class center of learning by providing joyful, 
      relevant, and value-based education to every child. We emphasize innovation, 
      academic excellence, intellectual curiosity, emotional stability, adaptability, 
      and respect for culture, all while nurturing goodwill ambassadors for the world.
    </p>

    <div style={{ textAlign: "center", marginBottom: "10px" }}>
                <img
                    src={`${process.env.PUBLIC_URL}/img/leadership.png`} // Correct the path based on your project structure
                    alt="Microphone Icon"
                    style={{
                    width: "30px", // Adjust the size as needed
                    height: "30px",
                    }}
                />
            </div>
    <h4 style={{ fontWeight: "bold", color: "#5f055f",textAlign:"center"  }}>
      Our Mission
    </h4>
    <p style={{ fontSize: "1.1rem" }}>
      To guide every child to achieve success, develop personality, and mold them into 
      responsible citizens with core humane values. Our mission is to motivate students 
      to acquire knowledge, skills, and confidence to achieve the impossible, contribute 
      to peace, and foster progress globally.
    </p>
  </div>
</div>

                    {/* Achievements Section */}
<div
  className="container mt-5"
  data-aos="fade-up" // AOS Animation for the entire section
>
  {/* Heading */}
  <h2
    className="text-center mb-4"
    style={{ color: "#5f055f", fontWeight: "bold" }}
  >
    Achievements
  </h2>

  {/* Row for Achievement Images */}
  <div className="row text-center">
    {/* Achievement 1 */}
    <div className="col-md-3">
      <img
        src={`${process.env.PUBLIC_URL}/img/achievement1.png`} // Replace with actual file path for image 1
        alt="2019 CCQCE Excellence Award"
        className="img-fluid"
        style={{ borderRadius: "10px", width: "100%" }}
      />
      <p style={{ marginTop: "10px", fontWeight: "bold", color: "#535050" }}>
        2019 CCQCE Excellence Award
      </p>
    </div>

    {/* Achievement 2 */}
    <div className="col-md-3">
      <img
        src={`${process.env.PUBLIC_URL}/img/achievement2.png`} // Replace with actual file path for image 2
        alt="2019-2020 Chinmaya Geeta Chanting Winner"
        className="img-fluid"
        style={{ borderRadius: "10px", width: "100%" }}
      />
      <p style={{ marginTop: "10px", fontWeight: "bold", color: "#535050" }}>
        2019-2020 Chinmaya Geeta Chanting Winner
      </p>
    </div>

    {/* Achievement 3 */}
    <div className="col-md-3">
      <img
        src={`${process.env.PUBLIC_URL}/img/achievement3.png`} // Replace with actual file path for image 3
        alt="2019-2020 Bhagavatam Winner"
        className="img-fluid"
        style={{ borderRadius: "10px", width: "100%" }}
      />
      <p style={{ marginTop: "10px", fontWeight: "bold", color: "#535050" }}>
        2019-2020 Bhagavatam Winner
      </p>
    </div>

    {/* Achievement 4 */}
    <div className="col-md-3">
      <img
        src={`${process.env.PUBLIC_URL}/img/achievement4.png`} // Replace with actual file path for image 4
        alt="2021 All India Invitational Yoga Champion"
        className="img-fluid"
        style={{ borderRadius: "10px", width: "100%" }}
      />
      <p style={{ marginTop: "10px", fontWeight: "bold", color: "#535050" }}>
        2021 All India Invitational Yoga Championship
      </p>
    </div>
  </div>
</div>




    </div>
    </div>
    </div>
  );
}

export default WhyChooseUs;
