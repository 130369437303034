import React, { useState } from "react";
import "./FAQSection.css";

function FAQSection() {
  const faqs = [
    {
      question: "How to Apply?",
      answer: `
        To complete the registration process, please click the "Apply Now" button on the website. 
        Alternatively, you can visit our school directly to fulfill the registration and enrollment process. 
        The completed registration form needs to be submitted with the applicable registration fee and documents between 8:30 AM to 4:30 PM (Monday-Saturday) at the respective schools.`,
    },
    {
      question: "List of Documents Required",
      answer: `
        - Pre KG to Grade 1: Original Birth Certificate. 
        - Grades 2 and above: Original Report Card or a copy of the Mark Sheet duly signed by the Head of the school (if available) where the child is presently studying.`,
    },
    {
      question: "Age Eligibility Criteria",
      answer: `
        - Pre KG: Born on or before Dec 31st, 2022. 
        - LKG: Born on or before Dec 31st, 2021. 
        - UKG: Born on or before Dec 31st, 2020. 
        - Grade I: Born on or before Dec 31st, 2019. 
        - Grade II and above: Age as per the Transfer Certificate.`,
    },
    {
      question: "Admission Procedure",
      answer: `
        Applications for admission can be submitted via the online form. 
        Alternatively, the hard copy of the application can be collected from the school campus, filled in, and submitted physically.`,
    },
    {
      question: "School Location",
      answer: "The exact location of the school can be previewed via the map available on our website.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-section">
      <h2 className="faq-title">Frequently Asked Questions</h2>
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <button
            className={`faq-question ${activeIndex === index ? "active" : ""}`}
            onClick={() => toggleAccordion(index)}
          >
            {faq.question}
          </button>
          <div
            className={`faq-answer ${activeIndex === index ? "show" : ""}`}
          >
            <p>{faq.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default FAQSection;