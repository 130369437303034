import React, { useEffect } from "react";
import AOS from "aos"; // Import AOS library
import "aos/dist/aos.css"; // Import AOS styles
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Ensures Bootstrap's JS functionalities work
import "../index.css";
import { Carousel } from "react-bootstrap";
import AboutUs from "./AboutUs";
import WhyChooseUs from "./WhyChooseUs";
import AchievementsHighlight from "./AcheivementHighlight";

function HomePage() {
  // Initialize AOS and ensure Bootstrap's carousel is properly loaded
  useEffect(() => {
    // Initialize AOS with `once: false` and `mirror: true`
    AOS.init({
      duration: 1000, // Animation duration in ms
      easing: "ease-in-out", // Animation easing function
      once: false, // Ensure animations trigger multiple times on scrolling up and down
      mirror: true, // Ensure animations trigger when scrolling back up
    });

    // Initialize Bootstrap Carousel
    const carouselElement = document.querySelector("#carouselExampleIndicators");
    if (carouselElement && window.bootstrap) {
      new window.bootstrap.Carousel(carouselElement, {
        interval: 3000, // Auto-slide interval in ms
        ride: "carousel", // Enables auto-slide
      });
    } else {
      console.error("Bootstrap JavaScript is not loaded properly.");
    }
  }, []); // Run once when the component is mounted

  return (
    <div className="container mt-2">
      {/* Carousel Section */}
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="img/car1.jpg"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="img/car2.jpg"
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="img/car3.jpg"
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="img/car4.jpg"
            alt="Fourth slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="img/car5.jpg"
            alt="Fifth slide"
          />
        </Carousel.Item>
      </Carousel>

      {/* About Us Section */}
      <AboutUs />

      {/* Why Choose Us Section */}
      <WhyChooseUs />

      {/* Acheivement owl caro */}
      <AchievementsHighlight/>
    </div>
  );
}

export default HomePage;