// import React, { useEffect, useState } from "react";
// import supabase from "../../../../supabaseClient";
// import "./MediaManagementPage.css";

// function MediaManagementPage() {
//   const [mediaItems, setMediaItems] = useState([]);
//   const [notifications, setNotifications] = useState([]);
//   const [announcements, setAnnouncements] = useState([]);
//   const [loading, setLoading] = useState(true);


//   // Fetch notifications and announcements from Supabase
//   useEffect(() => {
//     const fetchData = async () => {
//       const { data: notificationsData, error: notificationsError } = await supabase
//         .from("notifications")
//         .select("*");
//       const { data: announcementsData, error: announcementsError } = await supabase
//         .from("announcements")
//         .select("*");

//       if (notificationsError) {
//         console.error("Error fetching notifications:", notificationsError.message);
//       } else {
//         setNotifications(notificationsData);
//       }

//       if (announcementsError) {
//         console.error("Error fetching announcements:", announcementsError.message);
//       } else {
//         setAnnouncements(announcementsData);
//       }

//       setLoading(false);
//     };

//     fetchData();
//   }, []);

//   if (loading) return <p>Loading...</p>;

//   return (
//     <div className="three-column-layout">
//       {/* Notifications Section */}
//       <div className="notifications-section">
//         <div className="section-header">
//           <i className="fas fa-bell section-icon"></i>
//           <h2 className="section-title">Notifications</h2>
//         </div>
//         <ul className="notification-list">
//           {notifications.map((notification) => (
//             <li key={notification.id} className="notification-item">
//               <h3>{notification.title}</h3>
//               <p>{notification.description}</p>
//               <small>{new Date(notification.created_at).toLocaleDateString()}</small>
//             </li>
//           ))}
//         </ul>
//       </div>

//       {/* Announcements Section */}
//       <div className="announcements-section">
//         <div className="section-header">
//           <i className="fas fa-bullhorn section-icon"></i>
//           <h2 className="section-title">Announcements</h2>
//         </div>
//         <ul className="announcement-list">
//           {announcements.map((announcement) => (
//             <li key={announcement.id} className="announcement-item">
//               <h3>{announcement.title}</h3>
//               <p>{announcement.content}</p>
//               <small>{new Date(announcement.created_at).toLocaleDateString()}</small>
//             </li>
//           ))}
//         </ul>
//       </div>


//       {/* Media Library Section */}
//       <div className="media-library-section">
//         <div className="section-header">
//           <i className="fas fa-film section-icon"></i>
//           <h2 className="section-title">Media Library</h2>
//         </div>
//         <div className="media-vertical-scroller">
//           {mediaItems.length > 0 ? (
//             mediaItems.map((item) => (
//               <div key={item.id} className="media-item">
//                 <span className="media-title">{item.title}</span>
//                 <a
//                   href={item.link}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="media-link"
//                 >
//                   <i className="fas fa-film media-icon"></i>
//                 </a>
                
//               </div>
//             ))
//           ) : (
//             <p>No media items found.</p>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default MediaManagementPage;



import React, { useState, useEffect } from "react";
import supabase from "../../../../supabaseClient";
import "./MediaManagementPage.css";

function MediaManagementPage() {
  const [notifications, setNotifications] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [mediaLibrary, setMediaLibrary] = useState([]);
  // Separate state variables for forms
  const [newNotification, setNewNotification] = useState({ title: "", description: "" });
  const [newAnnouncement, setNewAnnouncement] = useState({ title: "", content: "" });

  const [editNotification, setEditNotification] = useState(null); // For editing notifications
  const [editAnnouncement, setEditAnnouncement] = useState(null); // For editing a

  const [newData, setNewData] = useState({ title: "", content: "", link: "" });
  const [editData, setEditData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch data from Supabase
  useEffect(() => {
    const fetchData = async () => {
      const { data: notificationsData } = await supabase.from("notifications").select("*");
      const { data: announcementsData } = await supabase.from("announcements").select("*");
      const { data: mediaData } = await supabase.from("media_library").select("*");

      setNotifications(notificationsData || []);
      setAnnouncements(announcementsData || []);
      setMediaLibrary(mediaData || []);
      setLoading(false);
    };

    fetchData();
  }, []);

  // Handle input changes for Notifications form
  const handleNotificationChange = (e) => {
    const { name, value } = e.target;
    setNewNotification({ ...newNotification, [name]: value });
  };

  // Handle input changes for Announcements form
  const handleAnnouncementChange = (e) => {
    const { name, value } = e.target;
    setNewAnnouncement({ ...newAnnouncement, [name]: value });
  };



  // Handle form changes for add/edit
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewData({ ...newData, [name]: value });
  };

  // Add a new Notification to Supabase
  const addNotification = async () => {
    const { error } = await supabase.from("notifications").insert([newNotification]);
    if (error) {
      alert(`Error adding notification: ${error.message}`);
    } else {
      alert("Notification added successfully!");
      setNewNotification({ title: "", description: "" }); // Clear the form
      window.location.reload(); // Refresh data
    }
  };

  // Add a new Announcement to Supabase
  const addAnnouncement = async () => {
    const { error } = await supabase.from("announcements").insert([newAnnouncement]);
    if (error) {
      alert(`Error adding announcement: ${error.message}`);
    } else {
      alert("Announcement added successfully!");
      setNewAnnouncement({ title: "", content: "" }); // Clear the form
      window.location.reload(); // Refresh data
    }
  };

  // Save edited Notification
  const saveNotificationEdit = async () => {
    const { error } = await supabase
      .from("notifications")
      .update({ title: editNotification.title, description: editNotification.description })
      .eq("id", editNotification.id);
    if (error) {
      alert(`Error editing notification: ${error.message}`);
    } else {
      alert("Notification updated successfully!");
      setEditNotification(null); // Exit edit mode
      window.location.reload();
    }
  };

  // Save edited Announcement
  const saveAnnouncementEdit = async () => {
    const { error } = await supabase
      .from("announcements")
      .update({ title: editAnnouncement.title, content: editAnnouncement.content })
      .eq("id", editAnnouncement.id);
    if (error) {
      alert(`Error editing announcement: ${error.message}`);
    } else {
      alert("Announcement updated successfully!");
      setEditAnnouncement(null); // Exit edit mode
      window.location.reload();
    }
  };

  // Delete Notification
  const deleteNotification = async (id) => {
    const { error } = await supabase.from("notifications").delete().eq("id", id);
    if (error) {
      alert(`Error deleting notification: ${error.message}`);
    } else {
      alert("Notification deleted successfully!");
      window.location.reload();
    }
  };

  // Delete Announcement
  const deleteAnnouncement = async (id) => {
    const { error } = await supabase.from("announcements").delete().eq("id", id);
    if (error) {
      alert(`Error deleting announcement: ${error.message}`);
    } else {
      alert("Announcement deleted successfully!");
      window.location.reload();
    }
  };


  // Add data to Supabase
  const handleAdd = async (tableName) => {
    const { error } = await supabase.from(tableName).insert([newData]);
    if (error) {
      alert(`Error adding ${tableName}: ${error.message}`);
    } else {
      alert(`${tableName} added successfully!`);
      setNewData({ title: "", content: "", link: "" });
      window.location.reload(); // Refresh to fetch new data
    }
  };

  // Edit data in Supabase
  const handleSaveEdit = async (tableName) => {
    const { error } = await supabase
      .from(tableName)
      .update(editData)
      .eq("id", editData.id);
    if (error) {
      alert(`Error editing ${tableName}: ${error.message}`);
    } else {
      alert(`${tableName} updated successfully!`);
      setEditData(null);
      window.location.reload(); // Refresh to fetch updated data
    }
  };

  // Delete data from Supabase
  const handleDelete = async (tableName, id) => {
    const { error } = await supabase.from(tableName).delete().eq("id", id);
    if (error) {
      alert(`Error deleting ${tableName}: ${error.message}`);
    } else {
      alert(`${tableName} deleted successfully!`);
      window.location.reload(); // Refresh to fetch updated data
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="management-page">
     {/* Notifications Section */}
     <div className="notifications-section">
        <h1 className="section-title">Notifications</h1>
        <ul className="item-list">
          {notifications.map((notification) => (
            <li key={notification.id} className="item">
              {editNotification && editNotification.id === notification.id ? (
                <>
                  <input
                    type="text"
                    value={editNotification.title}
                    onChange={(e) =>
                      setEditNotification({ ...editNotification, title: e.target.value })
                    }
                  />
                  <textarea
                    value={editNotification.description}
                    onChange={(e) =>
                      setEditNotification({ ...editNotification, description: e.target.value })
                    }
                  />
                  <button onClick={saveNotificationEdit}>Save</button>
                </>
              ) : (
                <>
                  <h3>{notification.title}</h3>
                  <p>{notification.description}</p>
                  <button onClick={() => setEditNotification(notification)}>Edit</button>
                  <button onClick={() => deleteNotification(notification.id)}>Delete</button>
                </>
              )}
            </li>
          ))}
        </ul>
        <h3>Add New Notification</h3>
        <form>
          <input
            type="text"
            name="title"
            placeholder="Notification Title"
            value={newNotification.title}
            onChange={handleNotificationChange}
          />
          <textarea
            name="description"
            placeholder="Notification Description"
            value={newNotification.description}
            onChange={handleNotificationChange}
          />
          <button type="button" onClick={addNotification}>
            Add Notification
          </button>
        </form>
      </div>

      {/* Announcements Section */}
      <div className="announcements-section">
        <h1 className="section-title">Announcements</h1>
        <ul className="item-list">
          {announcements.map((announcement) => (
            <li key={announcement.id} className="item">
              {editAnnouncement && editAnnouncement.id === announcement.id ? (
                <>
                  <input
                    type="text"
                    value={editAnnouncement.title}
                    onChange={(e) =>
                      setEditAnnouncement({ ...editAnnouncement, title: e.target.value })
                    }
                  />
                  <textarea
                    value={editAnnouncement.content}
                    onChange={(e) =>
                      setEditAnnouncement({ ...editAnnouncement, content: e.target.value })
                    }
                  />
                  <button onClick={saveAnnouncementEdit}>Save</button>
                </>
              ) : (
                <>
                  <h3>{announcement.title}</h3>
                  <p>{announcement.content}</p>
                  <button onClick={() => setEditAnnouncement(announcement)}>Edit</button>
                  <button onClick={() => deleteAnnouncement(announcement.id)}>Delete</button>
                </>
              )}
            </li>
          ))}
        </ul>
        <h3>Add New Announcement</h3>
        <form>
          <input
            type="text"
            name="title"
            placeholder="Announcement Title"
            value={newAnnouncement.title}
            onChange={handleAnnouncementChange}
          />
          <textarea
            name="content"
            placeholder="Announcement Content"
            value={newAnnouncement.content}
            onChange={handleAnnouncementChange}
          />
          <button type="button" onClick={addAnnouncement}>
            Add Announcement
          </button>
        </form>
      </div>



      {/* Media Library Section */}
      <div className="section">
        <h2>Media Library</h2>
        <ul>
          {mediaLibrary.map((item) => (
            <li key={item.id}>
              {editData && editData.id === item.id ? (
                <form>
                  <input
                    type="text"
                    name="title"
                    value={editData.title}
                    onChange={(e) =>
                      setEditData({ ...editData, title: e.target.value })
                    }
                  />
                  <input
                    type="text"
                    name="link"
                    value={editData.link}
                    onChange={(e) =>
                      setEditData({ ...editData, link: e.target.value })
                    }
                  />
                  <button onClick={() => handleSaveEdit("media_library")}>
                    Save
                  </button>
                </form>
              ) : (
                <>
                  <h3>{item.title}</h3>
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    View
                  </a>
                  <button onClick={() => setEditData(item)}>Edit</button>
                  <button onClick={() => handleDelete("media_library", item.id)}>
                    Delete
                  </button>
                </>
              )}
            </li>
          ))}
        </ul>
        <form>
          <input
            type="text"
            name="title"
            placeholder="New Media Title"
            value={newData.title}
            onChange={handleInputChange}
          />
          <input
            type="url"
            name="link"
            placeholder="Media Link"
            value={newData.link}
            onChange={handleInputChange}
          />
          <button onClick={() => handleAdd("media_library")}>Add</button>
        </form>
      </div>
    </div>
  );
}

export default MediaManagementPage;