import React from "react";
import "./Transport.css"; // Custom CSS file for styling

function Transport() {
  return (
    <div className="transport-container">
      {/* Header Section */}
      <header className="transport-header">
        <h1 className="transport-title">Transport</h1>
      </header>

      {/* Rules and Regulations Section */}
      <section className="rules-section">
        <h2>Rules and Regulations</h2>
        <p>
          The school provides sufficient transport facilities on routes covering Sewapet,
          Nemilicherry, Pudhumavilangai, Tamaraipakkam, Pollivakam, Ondikuppam, and Pattarai.
          Routes are adjusted each year based on the needs of those who avail this facility.
        </p>
        <p>
          If the bus fee is not paid on time, the school transport facility will be
          stopped immediately for defaulters.
        </p>
      </section>

      {/* For Those Using School Bus / Van Facility */}
      <section className="bus-rules-section">
        <h2>For Those Who Use School Bus / Van Facility</h2>
        <ul className="rules-list">
          <li>SRMJV ensures the safety of children.</li>
          <li>Bus fees must be paid in the office:
            <ul>
              <li>Term I: Before 10th April</li>
              <li>Term II: Before 10th October</li>
            </ul>
          </li>
          <li>Children must be ready at the respective stops on time to avoid delays.</li>
          <li>Bus stops, once fixed, will not be changed.</li>
          <li>Even for short-term use of the bus, the full fee for the term will be collected.</li>
          <li>No refund of bus fees under any circumstances.</li>
          <li>If a child wants to stop using the bus, the office must be informed a week in advance.</li>
          <li>Whenever a child using the school bus is taken home by private transport, the bus conductor and class teacher must be informed in advance.</li>
          <li>Behavior in the bus is strictly monitored. Misbehavior may result in denial of transport facilities.</li>
          <li>Bus routes will be included in the student ID card.</li>
          <li>Parents should not give instructions to the driver or conductor.</li>
          <li>No additional stops will be provided under any circumstances.</li>
        </ul>
      </section>

      {/* Contact Details */}
      <section className="contact-section">
        <h2>Contact Information</h2>
        <p>
          For any queries, contact:<br />
          <strong>Mr. P. Sivashankaran</strong><br />
          Transport Manager<br />
          Mobile No: 9952703424
        </p>
      </section>
    </div>
  );
}

export default Transport;
