import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS animations
import "./Karuna.css"; // Import CSS for styling

function Karuna() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="karuna-section">
      <header className="karuna-header" data-aos="fade-up">
        <h1 className="karuna-title">Karuna Club</h1>
      </header>
      <div className="karuna-content" data-aos="fade-up">
        <p>
          Compassion, love, caring for animals, non-violence, and respecting animal welfare are important qualities that should be developed among children from a very young age. 
          Our Karuna Club activities provide an excellent scope for nurturing these values. The various events and activities organized by the Karuna Club help children develop kindness and empathy towards animals and pets.
        </p>
        <p>
          Activities such as Karuna March, drawing competitions, oratorical events, essay writing, and card designing competitions are greatly appreciated by our students.
        </p>
        <p>
          It is a pleasure to witness our children participating in these events with great enthusiasm and joy.
        </p>
      </div>
      <div className="karuna-gallery" data-aos="fade-up">
        <img
          src={`${process.env.PUBLIC_URL}/img/karuna/1.jpeg`}
          alt="Children working on art projects"
          className="karuna-image"
        />
        <img
          src={`${process.env.PUBLIC_URL}/img/karuna/2.jpeg`}
          alt="Group of students showcasing their completed work"
          className="karuna-image"
        />
      </div>
      <div className="karuna-gallery" data-aos="fade-up">
        <img
          src={`${process.env.PUBLIC_URL}/img/karuna/3.jpeg`}
          alt="Children working on art projects"
          className="karuna-image"
        />
        <img
          src={`${process.env.PUBLIC_URL}/img/karuna/4.jpeg`}
          alt="Group of students showcasing their completed work"
          className="karuna-image"
        />
      </div>

      <div className="karuna-content" data-aos="fade-up">
        <p>
        We conduct various competitions like drawing, card designing, oratorical, essay, collage work to promote vegetarianism and compassion towards animals.
        Our students go for a “Karnua March” with placards, by creating an awareness among the public the issues like “Rain Water Harvesting”,” Road Safety”, “Be Kind to Living Beings” etc.
        </p>
      </div>

      <div className="karuna-gallery" data-aos="fade-up">
        <img
          src={`${process.env.PUBLIC_URL}/img/karuna/5.jpeg`}
          alt="Children working on art projects"
          className="karuna-image"
        />
        <img
          src={`${process.env.PUBLIC_URL}/img/karuna/6.jpeg`}
          alt="Group of students showcasing their completed work"
          className="karuna-image"
        />
      </div>
     


    </div>
  );
}

export default Karuna;