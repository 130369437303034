import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import Footer from "../components/Footer";
import MediaManagementPage from "./components/NotificationPage/MediaManagement/MediaManagement";
import NotificationPage from "./components/NotificationPage/NotificationPage";

function VidhyapeethApp() {
  const [mediaLibrary, setMediaLibrary] = useState([]);

  // Load data from local storage when the app initializes
  useEffect(() => {
    const storedMedia = JSON.parse(localStorage.getItem("mediaLibrary"));
    if (storedMedia) {
      setMediaLibrary(storedMedia);
    }
  }, []);

  // Save mediaLibrary to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("mediaLibrary", JSON.stringify(mediaLibrary));
  }, [mediaLibrary]);

  return (
    <>
      <Header /> {/* Header for navigation */}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/manage-media"
          element={
            <MediaManagementPage
              mediaLibrary={mediaLibrary}
              setMediaLibrary={setMediaLibrary}
            />
          }
        />
        <Route
          path="/notifications"
          element={<NotificationPage mediaLibrary={mediaLibrary} />}
        />
      </Routes>

      <Footer />
    </>
  );
}

export default VidhyapeethApp;