import React from "react";
import "./BusRoutes.css"; // Custom CSS for styling

function BusRoutes() {
  const routesData = [
    {
      route: "R1",
      stages: [
        { stage: "Thamaraipakkam", pickup: "7:15 AM", drop: "4:45 PM" },
        { stage: "Vishnuvalkam", pickup: "7:25 AM", drop: "4:30 PM" },
        { stage: "Odilikadu", pickup: "7:40 AM", drop: "4:20 PM" },
        { stage: "Ikadu", pickup: "7:50 AM", drop: "4:10 PM" },
      ],
    },
    {
      route: "R2",
      stages: [
        { stage: "Pudhumavilangai", pickup: "7:15 AM", drop: "4:40 PM" },
        { stage: "Kadambathur", pickup: "7:40 AM", drop: "4:30 PM" },
        { stage: "Priyankuppam", pickup: "7:50 AM", drop: "4:20 PM" },
        { stage: "Thirupachur", pickup: "8:00 AM", drop: "4:00 PM" },
      ],
    },
    {
      route: "R3",
      stages: [
        { stage: "Pollivakam", pickup: "7:40 AM", drop: "4:25 PM" },
        { stage: "TCL", pickup: "7:50 AM", drop: "4:15 PM" },
        { stage: "Caterpillar", pickup: "8:00 AM", drop: "4:00 PM" },
      ],
    },
    {
      route: "R4",
      stages: [
        { stage: "Nemilicherry", pickup: "7:25 AM", drop: "4:50 PM" },
        { stage: "Thiruninravur", pickup: "7:40 AM", drop: "4:40 PM" },
        { stage: "Veppambattu", pickup: "7:55 AM", drop: "4:20 PM" },
      ],
    },
    {
      route: "R5",
      stages: [
        { stage: "Sewapet Railway Station", pickup: "7:30 AM", drop: "4:10 PM" },
        { stage: "Vellakulam", pickup: "7:45 AM", drop: "4:20 PM" },
        { stage: "Ramapuram", pickup: "7:50 AM", drop: "3:55 PM" },
        { stage: "Kakalur", pickup: "8:00 AM", drop: "3:50 PM" },
      ],
    },
    {
      route: "R6",
      stages: [
        { stage: "Ondikuppam", pickup: "7:30 AM", drop: "3:50 PM" },
        { stage: "Pattarai", pickup: "7:40 AM", drop: "4:10 PM" },
      ],
    },
  ];

  return (
    <div className="routes-container">
      <header className="routes-header">
        <h1 className="routes-title">Bus Routes</h1>
      </header>
      <div className="routes-table">
        <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Stage</th>
              <th>Pick-Up Time</th>
              <th>Drop-Off Time</th>
            </tr>
          </thead>
          <tbody>
            {routesData.map((route, routeIndex) => (
              <React.Fragment key={route.route}>
                {/* Route Header Row */}
                <tr className="route-header">
                  <td colSpan="4">{`Route: ${route.route}`}</td>
                </tr>
                {/* Stages */}
                {route.stages.map((stage, stageIndex) => (
                  <tr key={`${route.route}-${stageIndex}`}>
                    <td>{stageIndex + 1}</td>
                    <td>{stage.stage}</td>
                    <td>{stage.pickup}</td>
                    <td>{stage.drop}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default BusRoutes;
