// import React from "react";
// import "./SRMJVTeam.css"; // Custom CSS file for styling

// function SRMJVTeam() {
//   const teamMembers = [
//     {
//       name: "Sri. P Kishore Kumar Jain",
//       title: "Director",
//       imgSrc: `${process.env.PUBLIC_URL}/img/kishore-kumar.jpg`,
//       description: `
//         Mr. Kishore Kumar is a Commerce Graduate, very calm in nature and an easily
//         approachable person. His focus on encouraging students in education, sports, and
//         life skills is well known. He supports students and parents generously to ensure
//         continued education. He is a philanthropist, practical leader, and passionate
//         about driving quality education, believing firmly in its power to empower society.
//       `,
//     },
//     {
//       name: "Sri. P Padamchand Jain",
//       title: "Chairman",
//       imgSrc: `${process.env.PUBLIC_URL}/img/padamchand-jain.jpg`,
//       description: `
//         With a vision to transform education in the region, Mr. Padamchand Jain has been a
//         steadfast leader, ensuring the institution stays true to its mission of academic
//         excellence and holistic development.
//       `,
//     },
//     {
//       name: "Sri. B Roshan Kumar Jain",
//       title: "Vice Chairman",
//       imgSrc: `${process.env.PUBLIC_URL}/img/roshan-kumar.jpg`,
//       description: `
//         Mr. Roshan Kumar Jain plays a pivotal role in nurturing the institution's
//         students and faculty. His contributions have been instrumental in building a
//         culture of respect, integrity, and pursuit of excellence.
//       `,
//     },
//     {
//       name: "Sri. P Abinandan Jain",
//       title: "Trustee",
//       imgSrc: `${process.env.PUBLIC_URL}/img/abinandan-jain.jpg`,
//       description: `
//         Known for his innovative approach to education management, Mr. Abinandan Jain
//         focuses on preparing students for the challenges of tomorrow while staying rooted
//         in strong values and traditions.
//       `,
//     },
//   ];

//   return (
//     <div className="team-container">
//       {/* Header Section */}
//       <header className="team-header">
//         <h1 className="team-title">Our SRMJV Team</h1>
//         <p className="team-subtitle">
//           Meet the passionate individuals shaping the future of education at SRMJV.
//         </p>
//       </header>

//       {/* Team Members Section */}
//       <div className="team-grid">
//         {teamMembers.map((member, index) => (
//           <div key={index} className="team-card">
//             <img
//               src={member.imgSrc}
//               alt={`Photo of ${member.name}`}
//               className="team-photo"
//             />
//             <h2 className="team-name">{member.name}</h2>
//             <h3 className="team-title">{member.title}</h3>
//             <p className="team-description">{member.description}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default SRMJVTeam;


import React from "react";
import "./SRMJVTeam.css"; // Custom CSS file for styling

function SRMJVTeam() {
  const teamMembers = [
    {
        name: "Sri. P Kishore Kumar Jain",
        title: "Director",
        imgSrc: `${process.env.PUBLIC_URL}/img/srmjvteam/director.jpg`,
        description: `
          Mr. Kishore Kumar is a Commerce Graduate known for his calm nature and dedication
          to education, sports, and life skills. He is a philanthropist and practical leader
          with a focus on driving quality education. He believes strongly in empowering
          students through education to build a better society.
        `,
      },
    {
      name: "Dr. J. Ajeeth Prasath Jain",
      title: "Academic Advisor",
      imgSrc: `${process.env.PUBLIC_URL}/img/srmjvteam/acad.jpg`,
      description: `
        A dedicated and resourceful educator with a proven ability to create and monitor
        policies and practices that promote a safe learning environment, ensuring continuous
        improvements for teachers and students. He mentors educators in class instructions,
        lesson plans, and student assessment in conjunction with learning standards.
        
        Dr. Jain is the recipient of the National Best Teacher Award (2004) from Hon’ble
        Dr. A.P.J Abdul Kalam, President of India. He has held responsible positions in both
        CBSE and the Tamil Nadu Government. Currently, he is a member of the Education
        Committee of the Hindu Religious & Charitable Endowments Department, Tamil Nadu.
      `,
    },
    {
      name: "Smt. Jayashree Narayanan",
      title: "Principal – CBSE",
      imgSrc: `${process.env.PUBLIC_URL}/img/srmjvteam/cbseprinci.jpg`,
      description: `
        With over three decades of teaching experience, Smt. Jayashree Narayanan brings
        global best practices to Sri R.M. Jain Vidhyashram Senior Secondary School.
        
        She is a post-graduate in English and History and has prepared students of Grades
        10 and 12 for CBSE examinations with great success. She is the recipient of the
        “Best Teacher Award” from CSSC-North Cluster and served as city coordinator for
        NEET UG exams. She believes in building robust academic environments rooted in
        fundamental human values.
      `,
    },
    {
      name: "Smt. Sujithara Ravinder",
      title: "Principal – Matriculation",
      imgSrc: `${process.env.PUBLIC_URL}/img/srmjvteam/mat-princi.jpg`,
      description: `
        An accomplished academician with over two decades of experience, Smt. Sujithara
        Ravinder holds a master's degree in Commerce and English with B.Ed and M.Phil in
        Commerce. She brings innovative approaches to academic administration and has
        been instrumental in initiating 5S programs and leading audit teams.
        
        She was the recipient of the “Best Teacher Award” from The International Association
        of Lions Club Hindu Charitable Trust. Her ambition is to prepare students to excel
        globally while nurturing their minds with passion and values.
      `,
    },
    
  ];

  return (
    <div className="team-container">
      {/* Header Section */}
      <header className="team-header">
        <h1 className="team-title">Our SRMJV Team</h1>
        <p className="team-subtitle">
          Meet the passionate individuals shaping the future of education at SRMJV.
        </p>
      </header>

      {/* Team Members Section */}
      <div className="team-grid">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-card">
            <img
              src={member.imgSrc}
              alt={`Photo of ${member.name}`}
              className="team-photo"
            />
            <h2 className="team-name">{member.name}</h2>
            <h3 className="team-title">{member.title}</h3>
            <p className="team-description">{member.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SRMJVTeam;
