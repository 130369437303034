import React from "react";
import "../../index.css"; // Ensure your CSS file is linked

function StickySidebar() {
  return (
    <div
      style={{
        position: "fixed", // Ensures it stays in place while scrolling
        left:"0",
        top: "50%", // Vertically centers the sidebar
        transform: "translateY(-50%)", // Adjusts for proper vertical alignment
        zIndex: "1000", // Ensures it appears above other elements
      }}
    >
      {/* Enquiry Button */}
      <a
        href="/enquiry" /* Replace with the actual URL of your enquiry page */
        className="btn btn-primary enquiry-btn"
        style={{
          display: "block", // Keeps the button vertical
          backgroundColor: "#800080", // Purple color
          color: "#FFFFFF", // White text
          textDecoration: "none", // Removes underline
          fontWeight: "bold",
          padding: "10px 20px",
          textAlign: "center",
          marginBottom: "10px", // Adds spacing between buttons
          borderRadius: "0 5px 5px 0", // Rounded right edges
          writingMode: "vertical-rl", // Makes text vertical
          transform: "rotate(360deg)", // Rotates the text upright
          fontSize: "1.2rem", // Adjust font size for readability
        }}
      >
        Enquiry
      </a>

      {/* Social Media Buttons */}
      <div>
        <a
          href="https://www.facebook.com" /* Replace with your actual social media link */
          target="_blank"
          rel="noopener noreferrer"
          className="social-btn facebook"
        >
          <i className="fab fa-facebook-f"></i> {/* Facebook Icon */}
        </a>
        <a
          href="https://www.twitter.com" /* Replace with your actual social media link */
          target="_blank"
          rel="noopener noreferrer"
          className="social-btn twitter"
        >
          <i className="fab fa-twitter"></i> {/* Twitter Icon */}
        </a>
        <a
          href="https://www.instagram.com" /* Replace with your actual social media link */
          target="_blank"
          rel="noopener noreferrer"
          className="social-btn instagram"
        >
          <i className="fab fa-instagram"></i> {/* Instagram Icon */}
        </a>
        <a
          href="https://www.youtube.com" /* Replace with your actual social media link */
          target="_blank"
          rel="noopener noreferrer"
          className="social-btn youtube"
        >
          <i className="fab fa-youtube"></i> {/* YouTube Icon */}
        </a>
      </div>
    </div>
  );
}

export default StickySidebar;
