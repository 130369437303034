import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS animations
import "./RulesRegulations.css"; // Import CSS for styling
import { title } from "framer-motion/client";

function RulesRegulations() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const sections = [
   
      {
        title: "General & Discipline",
        content: [
          "Students must attend the school on all working days and be punctual for the assembly. Students must be at the school at least 10 minutes before the first bell.",
          "English should be spoken in the school premises to acquire proficiency in the language.",
          "The school uniform must be worn on all working days and on all school functions. Students not in proper uniform will not be allowed to attend classes.",
          "Students should obey the teachers and school authorities in all matters, and are required to follow the instructions given by the school authorities.",
          "Students should be polite and courteous. All students are urged to maintain high moral and ethical standards set by the school in their manners and conduct.",
          "Students should behave in a proper manner wherever they go. They should always remember that the school would be judged by their conduct. All pupils are answerable to the school authorities for their behaviour outside the school also.",
          "Pupils are responsible for the custody of their books and belongings.",
          "The students shall keep the classrooms and their surroundings neat and tidy.",
          "Students shall treat all school property with care. Students are forbidden to write or scribble on walls or furniture, dirty the classrooms, or damage any material.",
          "If a student sees any school property damaged, he/she should report the matter to the school office immediately.",
          "All students must take part in Physical Education and other co-curricular activities unless medically unfit or exempted by the Principal.",
          "The Class Teacher should be notified immediately of any change in the address or telephone number of the student or his/her parent/guardian.",
          "Parents/Guardians shall ensure that their child/ward devotes enough time at home to their studies and assignments.",
          "Students shall bring only vegetarian lunch to school. Consumption of non-vegetarian food is strictly prohibited within the campus.",
          "Parents/Guardians shall respond to the reports and information that the school sends them in a timely manner. The school community needs the full cooperation of the parents/guardians.",
          "Parents/Guardians who escort their child/ward to and from school shall leave them near the school gate and receive them at the gate. Parents/Guardians shall not enter the classroom or remain in the school premises.",
          "No student shall leave the school premises during school hours, without the permission of the Principal.",
          "No student shall stay back in the school premises after school hours except when advised by the Principal or teachers.",
          "Parents/Guardians should mention the class and section of their child/ward in all their correspondence.",
          "Parents must ensure that the mobile numbers provided are solely attended by them.",
          "Ensure that your ward brings the school diary daily along with the respective books and materials as per the timetable.",
          "Kindly discourage your children from driving to school on two-wheelers. This is in the interest of your child’s safety.",
          "Parents must take prior permission after informing the office to meet the class or subject teachers. The meeting can be scheduled only after school hours.",
          "Parents are requested to check the school website for regular updates on circulars or announcements.",
          "Students are strictly forbidden to bring pen drives, cell phones, sharp instruments, valuables, etc., to the class and to purchase any food from unauthorized dealers at or near the school premises.",
          "Bringing comics or objectionable literature into the school may lead to dismissal. If required, students may bring gadgets related to their subject with the permission of their respective teachers.",
          "No private tuition by school teachers shall be arranged."
        ],
      },
      {
        title: "Attendance & Leave",
        content: [
          "The school does not encourage leave-taking in general.",
          "If the student needs sick leave for more than 3 days, he/she must produce a Medical Certificate.",
          "Students are expected to be in school before 8:30 AM. Late coming will not be entertained.",
          "Long leave will be granted under unavoidable circumstances.",
          "Absenting oneself a day prior to a test or exam will not be allowed.",
          "Absenteeism on a working Saturday will not be allowed.",
          "Leave for attending functions, outstation events, etc., should be intimated prior to the leave-taking.",
        ],
      },
    
      {
        title: "Dress Code",
        content: [
          "Students must come to school in proper uniform and clean shoes with grey socks every day.",
          "Girls must wear a black hair band in case of short hair. Shoulder-length hair must be tied with two ponytails. Girls with long hair must make two plaits as part of the dress code.",
          "Boys must have a decent haircut regularly and keep their hair short and clean. Unkempt long hair and stylish cuts will not be entertained.",
          "Hair coloring, gold jewellery in any form, long nails, nail polish, huge wristwatches, or accessories are unacceptable for both girls and boys.",
          "Students coming to school on non-instructional days must still wear the School Uniform.",
          "Shoes should be regularly cleaned and polished. If not, defaulters will have to wash their dirty socks and polish their shoes in school."
        ],
      },
      
      {
        title: "Test/Retest Code",
        content: [
          "A student who is unwell must not attend the school.",
          "Students who take leave on test days will be marked absent for the test, and the paper will not be evaluated.",
          "Retests will be conducted only for students who are ‘on duty’ on a test day, i.e., students who represent the school for an academic or co-scholastic event will be permitted to sit for a retest.",
          "Students facing medical issues at the time of the exam will be given a retest if they produce a medical certificate."
        ],
      },
      
      {
        title: "Academics",
        content: [
          "It is mandatory for the students to attend special/remedial classes that have been scheduled in the student’s interest.",
          "Practical works, records, and similar assignments must be duly completed and attested as per the schedule. Students failing to meet deadlines will not be permitted to proceed with the practical work.",
          "Submission of assignments, projects, and written activities (both classwork and homework) for all subjects must be carried out on the specific date."
        ],
      },
    
      {
        title: "Parent - Teacher Interaction",
        content: [
          "The cooperation and goodwill of the parents/guardians are essential components to maintain an effective Parent-Teacher relationship. In order to provide maximum educational opportunities for the students, the school needs the involvement of the parents/guardians in all their activities. For instance, parents/guardians can help the teachers identify the talents and skills of the students.",
          "Parents/Guardians shall cooperate with the school by adhering to the rules and regulations, which may be amended from time to time based on changing needs.",
          "Parents/Guardians are encouraged to give positive suggestions for the improvement of academic standards.",
          "Parents/Guardians are requested to meet any teacher only after school hours by fixing an appointment."
        ],
      },
      
  ];

  const suggestionBox = {
    title: "Suggestion Box",
    content: [
      "A suggestion box is provided in the school campus. Parents/Guardians are welcome to offer their valuable suggestions for the betterment of the school."
    ],
  };
  
  return (
    <div className="rules-section">
      <header className="rules-header" data-aos="fade-up">
        <h1 className="rules-title">Rules & Regulations</h1>
      </header>
      <div className="rules-content">
        {sections.map((section, index) => (
          <div key={index} className="rules-section-card" data-aos="fade-up">
            <h2>{section.title}</h2>
            <ul>
              {section.content.map((point, i) => (
                <li key={i}>{point}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="suggestion-box" data-aos="fade-up">
          <h2>{suggestionBox.title}</h2>
          <p>{suggestionBox.content[0]}</p>
        </div>


    </div>
  );
}

export default RulesRegulations;