import React from "react";
import "aos/dist/aos.css";

function AboutUs() {
    
  return (
    <div className="container mt-5">
      <div className="row align-items-center"> {/* Align items vertically */}
        {/* About Us Content */}
        <div
          className="col-md-6"
          data-aos="fade-up" /* AOS animation for content fade-up */
          style={{ minHeight: "380px", display: "flex", flexDirection: "column", justifyContent: "center" }}
        >
          <h2 className="text-center mb-4" style={{ color: "#5f055f", fontWeight: "bold" }}>
            About the School
          </h2>
          <p style={{ lineHeight: "1.8", fontSize: "1.1rem", color: "#535050" }}>
            Sri. R.M. Jain Group of Schools, in its pursuit of holistic education, envisages inspiring
            and fostering a community of caring, progressive, and lifelong learners, who view success
            as more than just academic achievements. We nurture the personal, emotional, and spiritual
            growth of human beings.
          </p>
          <p style={{ lineHeight: "1.8", fontSize: "1.1rem", color: "#535050" }}>
            SRMJV endeavors to prepare each and every student for academic, social, and personal success
            by creating a community of empowered and diverse learners striving to be globally conscious
            citizens, in an atmosphere of mutual respect, understanding, and trust.
          </p>
          <p style={{ lineHeight: "1.8", fontSize: "1.1rem", color: "#535050" }}>
            The first-ever school in Tiruvallur to implement Total Quality Management. We have initiated
            the 5S program and our team was successful in getting an Excellence Award by CQCQE for
            implementing and practicing the same.
          </p>
        </div>

        {/* YouTube Video */}
        <div
          className="col-md-6"
          data-aos="fade-left" /* AOS animation for video fade-left */
          style={{ height: "380px", display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <iframe
            width="100%"
            height="100%" /* Ensures the video matches the height of the content */
            src="https://www.youtube.com/embed/cXduZU8Qrho"
            title="A Glimpse of SRMJV"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;