import React from "react";
import "./WhySRMJV.css"; // Custom CSS for styling

function WhySRMJV() {
  const reasons = [
    {
      icon: "fas fa-university", // Example icon
      title: "Rich Curriculum",
      description: "School curriculum that is rich with glimpses of Indian heritage and culture.",
    },
    {
      icon: "fas fa-child", // Example icon
      title: "Child-Centered Learning",
      description: "Focused on nurturing the individual needs of every child.",
    },
    {
      icon: "fas fa-user-friends", // Example icon
      title: "Dedicated Teachers",
      description: "A committed and dedicated team of teachers provide individualized attention.",
    },
    {
      icon: "fas fa-heart", // Example icon
      title: "Core Values",
      description: "Act with kindness, honesty, respect, and inter-cultural knowledge.",
    },
    {
      icon: "fas fa-leaf", // Example icon
      title: "Diversity & Sensitivity",
      description: "Nurturing sensitivity within each child to embrace diversity in the environment.",
    },
    {
      icon: "fas fa-users", // Example icon
      title: "Team Spirit",
      description: "Improving skills to work collaboratively and foster team spirit.",
    },
    {
      icon: "fas fa-balance-scale", // Example icon
      title: "Integrity & Relationships",
      description: "Developing the value of integrity through supportive student-teacher relationships.",
    },
    {
      icon: "fas fa-brain", // Example icon
      title: "Emotional & Intellectual Growth",
      description: "Providing excellent opportunities for growth, fine-tuning focus on a successful future.",
    },
    {
      icon: "fas fa-chalkboard-teacher", // Example icon
      title: "Active Learning",
      description: "Learner involvement with project-based learning and practical approaches.",
    },
  ];

  return (
    <div className="why-srmjv">
      <h1 className="title">Why Choose SRMJV?</h1>
      <div className="reason-cards">
        {reasons.map((reason, index) => (
          <div className="reason-card" key={index}>
            <i className={`${reason.icon} reason-icon`}></i>
            <h3 className="reason-title">{reason.title}</h3>
            <p className="reason-description">{reason.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WhySRMJV;