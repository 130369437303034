import React from "react";

import HeroSection from "../components/HeroSection/HeroSection";
import "./HomePage.css";
import Notification from "../components/Notification/Notification";
import CarouselPage from "../components/CarouselPage";
import WhySRMJV from "../components/Notification/WhySRMJV/WhySRMJV";
import EventPage from "../components/Notification/EventPage/EventPage";
import { Link } from "react-router-dom";
import TinyTotsBlock from "../components/TinyTots/TinyTots";
import LibraryClubSection from "../components/LibraryClubSection/LibraryClubSection";
import AlumniSection from "../components/AlumniSection/AlumniSection";
import AdmissionsSection from "../components/AdmissionSection/AdmissionsSection";
import FAQSection from "../components/FAQSection/FAQSection";

function Homepage() {
  return (
    <div className="homepage">
      
      <HeroSection />
      <Notification />
      <CarouselPage />
      <WhySRMJV />
      <EventPage />
      <div className="event-management">
        <Link to="/event/add" className="btn btn-primary">Add Event</Link>
        <Link to="/event/edit/1" className="btn btn-secondary">Edit Event</Link>
      </div>
      <TinyTotsBlock />
      <LibraryClubSection />
      <AlumniSection />
      <AdmissionsSection />
      <FAQSection />
    </div>
  );
}

export default Homepage;