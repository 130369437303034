import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Gallery.css";

function Gallery() {
  const [albums, setAlbums] = useState([
    {
      id: 1,
      title: "Album 1",
      thumbnail: `${process.env.PUBLIC_URL}/img/album1-thumbnail.jpg`,
      link: "https://photos.google.com/share/AF1QipPAQ6fPkcmRVAeElcc9c3c_khAfz_IzS2LlWGGp86ox0wmuH97da5yEI-ZiyTYpnA",
    },
    {
      id: 2,
      title: "Album 2",
      thumbnail: `${process.env.PUBLIC_URL}/img/album2-thumbnail.jpg`,
      link: "https://photos.google.com/share/AF1QipMHY3M_EmsvpGqwN6dsfG4_QiaFlOrR2Kwt9lVJMyCKcw2jVaKp_lp8Kb6VTJzHAA",
    },
  ]);
  const [newAlbum, setNewAlbum] = useState({ id: "", title: "", link: "", thumbnail: "" });
  const [isEditing, setIsEditing] = useState(false); // Track editing state

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleEditAlbum = (album) => {
    setNewAlbum(album); // Set album details for editing
    setIsEditing(true); // Switch to editing mode
  };

  const handleSaveAlbum = () => {
    if (newAlbum.title && newAlbum.link && newAlbum.thumbnail) {
      setAlbums((prevAlbums) =>
        prevAlbums.map((album) =>
          album.id === newAlbum.id ? { ...newAlbum } : album
        )
      );
      setIsEditing(false); // Exit editing mode
      setNewAlbum({ id: "", title: "", link: "", thumbnail: "" }); // Reset form
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAlbum((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div className="gallery-section">
      <header className="gallery-header" data-aos="fade-up">
        <h1 className="gallery-title">Gallery</h1>
      </header>
      <div className="gallery-content">
        <div className="gallery-grid" data-aos="fade-up">
          {albums.map((album) => (
            <div key={album.id} className="gallery-item">
              <a href={album.link} target="_blank" rel="noopener noreferrer">
                <img
                  src={album.thumbnail}
                  alt={album.title}
                  className="gallery-thumbnail"
                />
                <p className="gallery-title">{album.title}</p>
              </a>
              <button onClick={() => handleEditAlbum(album)} className="edit-button">
                Edit
              </button>
            </div>
          ))}
        </div>
        <div className="add-edit-album-form" data-aos="fade-down">
          <h2>{isEditing ? "Edit Album" : "Add New Album"}</h2>
          <input
            type="text"
            name="title"
            value={newAlbum.title}
            placeholder="Album Title"
            onChange={handleInputChange}
            className="input-field"
          />
          <input
            type="text"
            name="link"
            value={newAlbum.link}
            placeholder="Album Link"
            onChange={handleInputChange}
            className="input-field"
          />
          <input
            type="text"
            name="thumbnail"
            value={newAlbum.thumbnail}
            placeholder="Thumbnail URL"
            onChange={handleInputChange}
            className="input-field"
          />
          <button
            onClick={isEditing ? handleSaveAlbum : () => handleEditAlbum(newAlbum)}
            className="add-edit-button"
          >
            {isEditing ? "Save Changes" : "Add Album"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Gallery;