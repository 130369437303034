import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS animations
import "./EcoClub.css"; // Import CSS for styling

function EcoClub() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const ecoImages = [
    { src: `${process.env.PUBLIC_URL}/img/Eco/1.jpeg`, alt: "Eco Club activity 1" },
    { src: `${process.env.PUBLIC_URL}/img/Eco/2.jpeg`, alt: "Eco Club activity 2" },
    { src: `${process.env.PUBLIC_URL}/img/Eco/3.jpeg`, alt: "Eco Club activity 3" },
    { src: `${process.env.PUBLIC_URL}/img/Eco/4.jpeg`, alt: "Eco Club activity 4" },
    { src: `${process.env.PUBLIC_URL}/img/Eco/5.jpeg`, alt: "Eco Club activity 5" },
  ];

  return (
    <div className="eco-club-section">
      <header className="eco-club-header" data-aos="fade-up">
        <h1 className="eco-club-title">Eco Club</h1>
      </header>
      <div className="eco-club-content" data-aos="fade-up">
        <p>
          The overall objective of a school is not merely to produce graduates and postgraduates. We realize that the ultimate purpose of a school is to produce future citizens who are value-driven and responsible.
        </p>
        <p>
          Of all the activities we do in our school, the Eco Club activities deserve a special mention. The main objective of an Eco Club is to produce environmental awareness among children so that they respect, protect, and preserve nature. By participating in various Eco Club activities, children are taught to protect and respect Mother Nature.
        </p>
      </div>
      <div className="eco-club-gallery" data-aos="fade-up">
        {ecoImages.map((image, index) => (
          <div key={index} className="eco-club-image-container">
            <img
              src={image.src}
              alt={image.alt}
              className="eco-club-image"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default EcoClub;