import React from "react";
import "./TeachersTraining.css"; // Custom CSS for styling
import Slider from "react-slick"; // Import React-Slick
import "slick-carousel/slick/slick.css"; // Slick core styles
import "slick-carousel/slick/slick-theme.css"; // Slick theme styles

function TeachersTraining() {
  const trainingPhotos = [
    { src: `${process.env.PUBLIC_URL}/img/training/1.jpg`, alt: "Training Session 1" },
    { src: `${process.env.PUBLIC_URL}/img/training/2.jpg`, alt: "Training Session 1" },
    { src: `${process.env.PUBLIC_URL}/img/training/3.jpg`, alt: "Training Session 1" },
    { src: `${process.env.PUBLIC_URL}/img/training/4.jpg`, alt: "Training Session 1" },
    { src: `${process.env.PUBLIC_URL}/img/training/5.jpg`, alt: "Training Session 1" },
    { src: `${process.env.PUBLIC_URL}/img/training/6.jpg`, alt: "Training Session 1" },
    { src: `${process.env.PUBLIC_URL}/img/training/7.jpg`, alt: "Training Session 1" },
    { src: `${process.env.PUBLIC_URL}/img/training/8.jpg`, alt: "Training Session 1" },
    { src: `${process.env.PUBLIC_URL}/img/training/9.jpg`, alt: "Training Session 1" },
    { src: `${process.env.PUBLIC_URL}/img/training/10.jpg`, alt: "Training Session 1" },
    { src: `${process.env.PUBLIC_URL}/img/training/11.jpg`, alt: "Training Session 1" },
    { src: `${process.env.PUBLIC_URL}/img/training/12.jpg`, alt: "Training Session 1" },
    { src: `${process.env.PUBLIC_URL}/img/training/13.jpg`, alt: "Training Session 1" },
    
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Display 3 images at a time for Owl Carousel-like layout
    slidesToScroll: 1, // Scroll one image at a time
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  return (
    <div className="training-container">
      {/* Header Section */}
      <header className="training-header">
        <h1 className="training-title">Teachers Training</h1>
      </header>

      {/* Training Information Section */}
      <section className="training-info">
        <h2>Training Process</h2>
        <p>
          At SRMJV, we understand the pivotal role teachers play in nurturing and shaping young minds.
          Our teacher training program is designed to foster a sense of inquisitiveness in students
          and empower educators with the skills they need to inspire and lead.
        </p>

        <h3>Phase 1: Class Observations</h3>
        <p>
          During the first phase, the Heads of Departments (HODs) and Principals observe classes to
          identify specific areas for improvement. These observations serve as the foundation for
          designing effective training interventions.
        </p>

        <h3>Phase 2: Comprehensive Training</h3>
        <ul>
          <li>
            <strong>At the Beginning of the Academic Year:</strong> Teachers undergo an initial induction
            program focusing on teaching methodologies, classroom control, and pedagogy.
          </li>
          <li>
            <strong>Ongoing Training:</strong> Includes curriculum workshops, lesson planning guidance,
            micro-teaching sessions, and discussions with the Principal and HODs.
          </li>
          <li>
            <strong>In-house Training:</strong> Teachers receive training on quality tools and techniques
            to enhance their effectiveness.
          </li>
          <li>
            <strong>Outsource Facilitation:</strong> Experts conduct workshops and seminars to upskill
            teachers in modern teaching methodologies. Programs like the CBP (Capacity Building
            Programs) by COE also play a key role in teacher development.
          </li>
        </ul>
      </section>

      {/* Carousel Section */}
      <section className="training-carousel">
        <h2>Training Highlights</h2>
        <Slider {...settings}>
          {trainingPhotos.map((photo, index) => (
            <div key={index} className="carousel-item">
              <img src={photo.src} alt={photo.alt} className="carousel-image" />
            </div>
          ))}
        </Slider>
      </section>
    </div>
  );
}

export default TeachersTraining;