import React from "react";
import "./WhyChooseSRMJV.css"; // Custom CSS for styling

function WhyChooseUs() {
  const reasons = [
    {
      icon: "fas fa-university", // Example icon
      title: "Academics",
      description: "SRMJV school gives strong intellectual, moral and practical support to children, considering Social, Emotional and Personality development. Character development is essential and so integrated with academic competence and success. SRMJV has an advantage of academic rigour and iterative approach where a student’s academic progress is monitored on a daily, weekly basis and end of every term. This school offer’s a gamut of co-curricular activities like Yoga, Music, Dance, Arts & Physical Education leading to an overall development of your ward.",
    },
    {
      icon: "fas fa-child", // Example icon
      title: "Curriculum",
      description: "SRMJV follows Matriculation syllabus. There is a great focus and emphasis on social and communication skills, as well as collaboration and exchange of ideas. The year of Mathematic drive, story teller club, Swagatham, cooperative learning time in SRMJV school are all samples of this. This is in contrast to the rote learning that happens in ordinary schools. ",
    },
    {
      icon: "fas fa-user-friends", // Example icon
      title: "IIT Exposure program",
      description: "We have an in-built IIT Exposure program for students of classes 9 to 12, and also Integrated Coaching for Competitive exams like NTSE,IIT & NEET for classes 9-12. We prepare students for Spell Bee English Certification and ensure each year the students of grades 1 to 9 get the certificates. We also prepare students for Olympiad exams.",
    },
    {
      icon: "fas fa-heart", // Example icon
      title: "Annual Day",
      description: "A new element of our strong visual act program is the annual day programme which features inherent talents of our students.",
    },
    {
      icon: "fas fa-leaf", // Example icon
      title: "Student’s Life",
      description: "The motto of our school is Global mind with an Indian Heart. All the staff and students of the school are expected to keep the school motto always uppermost in their minds and ensure success and happiness in their every walks of life. They should inculcate the feeling of honours for the traditional values and cultural heritage of India.",
    },
    {
      icon: "fas fa-users", // Example icon
      title: "SRMJV Election Commission",
      description: "Each year we organise election in our school and the office bearers are selected by our students. To prepare them for a good citizenship.",
    },
    
  ];

  return (
    <div className="why-srmjv">
      <h1 className="title">Why Choose SRMJV?</h1>
      <div className="reason-cards">
        {reasons.map((reason, index) => (
          <div className="reason-card" key={index}>
            <i className={`${reason.icon} reason-icon`}></i>
            <h3 className="reason-title">{reason.title}</h3>
            <p className="reason-description">{reason.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WhyChooseUs;