import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import "./BadmintonCourt.css"; // Import the CSS for styling

function BadmintonCourt() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="badminton-section">
      <header className="badminton-header" data-aos="fade-up">
        <h1 className="badminton-title">Badminton Court Facilities</h1>
      </header>
      <div className="badminton-content">
        <div className="badminton-description" data-aos="fade-down">
          <p>
            The school has a well-constructed badminton court of size <strong>7921 sq.ft.</strong>, providing students 
            with excellent opportunities to train and play. This facility is accompanied by <strong>well-trained professional trainers</strong>, 
            ensuring quality guidance and skill development.
          </p>
        </div>
        <div className="badminton-images" data-aos="fade-up">
          <img
            src={`${process.env.PUBLIC_URL}/img/badminton1.jpg`}
            alt="Badminton Court View 1"
            className="badminton-image"
          />
          <img
            src={`${process.env.PUBLIC_URL}/img/badminton2.jpg`}
            alt="Badminton Court View 2"
            className="badminton-image"
          />
        </div>
      </div>
    </div>
  );
}

export default BadmintonCourt;