import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS animations
import "./Achievements.css"; // Import CSS for styling

function Achievements() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const achievementsData = [
    {
      event: "35th Tamil Nadu State Taekwondo Championship",
      date: "25-Jan-2025",
      studentName: "Sarah Sri J (Class IV C - 7484)",
      position: "Runner Up",
      type: "Sports",
      description: "Our student Sarah Sri J of Class IV C was declared Runner Up in the championship.",
      imageUrl: `${process.env.PUBLIC_URL}/img/ach1.jpg`, 
    },
  ];

  return (
    <div className="achievements-section">
      <header className="achievements-header" data-aos="fade-in">
        <h1 className="achievements-title">Achievements</h1>
      </header>
      <div className="achievements-content">
        {achievementsData.map((achievement, index) => (
          <div key={index} className="achievement-card" data-aos="fade-up">
            <div className="achievement-image-container">
              <img
                src={achievement.imageUrl}
                alt={achievement.event}
                className="achievement-image"
              />
            </div>
            <div className="achievement-details">
              <h2>{achievement.event}</h2>
              <p><strong>Date:</strong> {achievement.date}</p>
              <p><strong>Name of the Student:</strong> {achievement.studentName}</p>
              <p><strong>Position:</strong> {achievement.position}</p>
              <p><strong>Type:</strong> {achievement.type}</p>
              <p><strong>Description:</strong> {achievement.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Achievements;