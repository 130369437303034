import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import "./Curriculum.css"; // Import CSS for styling

function Curriculum() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const curriculumData = [
    { title: "K.G (Kindergarten)", details: "Orient Black Swan" },
    { title: "I - II", details: "Subjects: English, Tamil, Hindi, Sanskrit, Maths, Environmental Science" },
    { title: "III - V", details: "Subjects: English, Tamil, Hindi, Sanskrit, Maths, Science" },
    { title: "VI - VIII", details: "Subjects: English, Tamil, Hindi, Sanskrit, Maths, Science, Social Science, Artificial Intelligence" },
    { title: "IX - X", details: "Subjects: English, Tamil, Hindi, Sanskrit, Maths, Science, Social Science, Artificial Intelligence" },
  ];

  const matricCurriculum = [
    { group: "Classes I to VIII", details: "Follows Matriculation Syllabus" },
    { group: "Group I", details: "Physics, Chemistry, Mathematics, and Computer Science" },
    { group: "Group II", details: "Physics, Chemistry, Mathematics, and Biology" },
    { group: "Group III", details: "Economics, Commerce, Accountancy, and Computer Applications" },
    { group: "Group IV", details: "Economics, Commerce, Accountancy, and Mathematics" },
  ];

  const examScheduleData = [
    { grade: "I - VIII", schedule: [
      { month: "July", exams: "PA - I" },
      { month: "September", exams: "TA - I" },
      { month: "November", exams: "PA - II" },
      { month: "December", exams: "TA - II" },
      { month: "February", exams: "PA - III" },
      { month: "March", exams: "TA - III" },
    ]},
    { grade: "IX & XI", schedule: [
      { month: "June", exams: "PA - I (IX Only)" },
      { month: "July", exams: "PA - I (XI Only)" },
      { month: "August", exams: "PA - II" },
      { month: "September", exams: "TA - I" },
      { month: "October", exams: "PA - III" },
      { month: "December", exams: "TA - II" },
      { month: "January & February", exams: "Annual Exam" },
    ]},
    { grade: "X & XII", schedule: [
      { month: "April", exams: "PA - I" },
      { month: "June", exams: "PA - II" },
      { month: "July", exams: "PA - III" },
      { month: "August", exams: "PA - IV" },
      { month: "September", exams: "TA - I" },
      { month: "October", exams: "MICRO - I" },
      { month: "November", exams: "MICRO - II & MICRO - III" },
      { month: "November & December", exams: "REVISION I & REVISION II" },
      { month: "January", exams: "REVISION III (X & XII) & REVISION IV (X ONLY)" },
      { month: "March", exams: "SSCE / SSE" },
    ]},
  ];

  return (
    <div className="curriculum-section">
      <header className="curriculum-header" data-aos="fade-up">
        <h1 className="curriculum-title">Curriculum</h1>
      </header>
      <div className="curriculum-grid">
        {curriculumData.map((curriculum, index) => (
          <div className="curriculum-card" key={index} data-aos="fade-up">
            <h2>{curriculum.title}</h2>
            <p>{curriculum.details}</p>
          </div>
        ))}
      </div>
      <header className="exam-schedule-header" data-aos="fade-up">
        <h2 className="exam-schedule-title">Examination Schedule</h2>
      </header>
      <div className="exam-schedule-section">
        {examScheduleData.map((exam, index) => (
          <div key={index} className="exam-schedule" data-aos="fade-up">
            <h3>{exam.grade}</h3>
            <table className="exam-schedule-table">
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Exams</th>
                </tr>
              </thead>
              <tbody>
                {exam.schedule.map((item, i) => (
                  <tr key={i}>
                    <td>{item.month}</td>
                    <td>{item.exams}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
      <header className="matric-header" data-aos="fade-up">
        <h2 className="matric-title">Matriculation / State Curriculum</h2>
      </header>
      <div className="matric-grid">
        {matricCurriculum.map((group, index) => (
          <div className="curriculum-card" key={index} data-aos="fade-up">
            <h2>{group.group}</h2>
            <p>{group.details}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Curriculum;