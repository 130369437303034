import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import StickySidebar from "./components/shared/StickySidebar";
import SwitchSchools from "./components/switchschools/SwitchSchools";
import HomePage from "./components/HomePage";
import OurPurpose from "./components/Ourpurpose/OurPurpose";
import WeAreThankful from "./components/WeAreThankful/WeAreThankful";
import Administration from "./components/Administration/Administration";
import SRMJVTeam from "./components/SRMJVTeam/SRMJVTeam";
import Transport from "./components/Transport/Transport";
import BusRoutes from "./components/Transport/BusRoutes";
import TeachersTraining from "./components/TeachersTraining/TeachersTraining";
import CareerOpportunities from "./components/CareerOpportunities/CareerOpportunities";
import ParentPortal from "./components/ParentPortal/ParentPortal";
import ContactUs from "./components/ContactUs/ContactUs";

import VidhyashramApp from "./Vidhyashram/VidhyashramApp";
import VidhyapeethApp from "./Vidhyapeeth/App";

function App() {
  return (
    <Router>
      <MainContent />
    </Router>
  );
}

function MainContent() {
  const location = useLocation(); // Get the current route
  const isVidhyashramRoute = location.pathname.startsWith("/vidhyashram"); // Check if the route is for Vidhyashram
  const isVidhyapeethRoute = location.pathname.startsWith("/vidhyapeeth");

  return (
    <div>
      {/* Conditionally render Header, StickySidebar, and SwitchSchools */}
      {!isVidhyashramRoute && !isVidhyapeethRoute  && (
        <>
          <Header />
          
        
        </>
      )}

      {/* Define Routes for Pages */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/our-purpose" element={<OurPurpose />} />
        <Route path="/wearethankful" element={<WeAreThankful />} />
        <Route path="/administration" element={<Administration />} />
        <Route path="/team" element={<SRMJVTeam />} />
        <Route path="/transportrules" element={<Transport />} />
        <Route path="/Bus-Routes" element={<BusRoutes />} />
        <Route path="/training" element={<TeachersTraining />} />
        <Route path="/careers" element={<CareerOpportunities />} />
        <Route path="/Parentportal" element={<ParentPortal />} />
        <Route path="/Contactus" element={<ContactUs />} />

        {/* Vidhyashram App */}
        <Route path="/vidhyashram/*" element={<VidhyashramApp />} />
        <Route path="/vidhyapeeth/*" element={<VidhyapeethApp />} />
      </Routes>


      <StickySidebar />
          <SwitchSchools />
      
  
    </div>
  );
}

export default App;