import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function AddEvent({ addEvent }) { // Accept `addEvent` as a prop
  const [formData, setFormData] = useState({
    name: "",
    date: "",
    description: "",
  });

  const navigate = useNavigate(); // For navigation after adding

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addEvent(formData); // Call the prop function to save the event
    alert("Event added successfully!");
    navigate("/"); // Redirect to the homepage
  };

  return (
    <div className="add-event">
      <h1>Add Event</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Event Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Event Date:</label>
          <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Description:</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit">Add Event</button>
      </form>
    </div>
  );
}

export default AddEvent;