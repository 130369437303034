import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import "./SchoolGround.css"; // Import CSS for styling

function SchoolGround() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="smart-board-section">
      <header className="smart-board-header" data-aos="fade-up">
        <h1 className="smart-board-title">Play Ground</h1>
      </header>
      <div className="smart-board-content">
        <div className="smart-board-description" data-aos="fade-down">
          <p>
          Sports is a very essential part of the students activities in the campus. The campus has a huge playground of over 3565 sq meters.
          </p>
        </div>
        <div className="smart-board-images" data-aos="fade-up">
          <img
            src={`${process.env.PUBLIC_URL}/img/sg1.jpeg`}
            alt="Classroom Smart Board Display"
            className="smart-board-image"
          />
          <img
            src={`${process.env.PUBLIC_URL}/img/sg2.jpeg`}
            alt="Teacher Using a Smart Board"
            className="smart-board-image"
          />
          <img
            src={`${process.env.PUBLIC_URL}/img/sg3.jpeg`}
            alt="Teacher Using a Smart Board"
            className="smart-board-image"
          />
          <img
            src={`${process.env.PUBLIC_URL}/img/sg4.jpeg`}
            alt="Teacher Using a Smart Board"
            className="smart-board-image"
          />
        </div>
      </div>
    </div>
  );
}

export default SchoolGround;