import React from "react";
import "./WeAreThankful.css"; // Custom CSS file for styling

function WeAreThankful() {
  return (
    <div className="thankful-container">
      {/* Header Section */}
      <header className="thankful-header">
        <h1 className="thankful-title">We Are Thankful</h1>
      </header>

      {/* Content Section */}
      <section className="thankful-content">
        <div className="story">
          <p>
            In 1996, when we wanted to start a school in Tiruvallur, we were determined
            that it wouldn’t just be a school but a **Movement on Personality Development
            and Parents Empowerment**. Starting a CBSE school in a village was considered
            ambitious—many called it a "crazy" idea. But our passion drove us forward.
          </p>
        </div>

        {/* Inspiration Section */}
        <div className="patanchali-section">
          <h2>We were driven by the verses of Patanjali:</h2>
          <blockquote className="patanchali-quote">
            "When you are inspired by some great purpose, some extraordinary project, all
            your thoughts break their bounds. Your mind transcends limitations, your
            consciousness expands in every direction, and you find yourself in a new,
            great, and wonderful world. Dormant forces, faculties, and talents become
            alive, and you discover yourself to be a greater person by far than you ever
            dreamed yourself to be."
          </blockquote>
        </div>

        {/* Mentorship Section */}
        <div className="mentorship-section">
          <h2>Padmashri Dr. Mrs. YG. Parthasarathy</h2>
          <div className="mentor-photo">
            <img
              src={`${process.env.PUBLIC_URL}/img/ygp.jpg`}
              alt="Padmashri Dr. Mrs. YG. Parthasarathy"
              className="mentor-image"
            />
          </div>
          <p>
            We also know that giving shape to such a lofty ambition required not just
            concrete structures and classrooms, but the guidance and mentorship of people
            who had already succeeded. We were incredibly fortunate to have the
            leadership and emotional support of Padmashri Dr. Mrs. YG. Parthasarathy, the
            Dean and Director of PSBB Group of Schools, Chennai.
          </p>
          <p>
            Her model, combining rich Indian cultural values with high academic
            excellence through the Padma Seshadri Group of Schools, inspired us. It
            became the foundation we sought to emulate in our educational journey.
          </p>
        </div>

        {/* Contribution Section: Mr. S. Varadarajan */}
        <div className="contribution-section">
          <h2>Gratitude to Mr. S. Varadarajan</h2>
          <div className="mentor-photo">
            <img
              src={`${process.env.PUBLIC_URL}/img/varadharajan-sir.jpg`}
              alt="Mr. S. Varadarajan"
              className="mentor-image"
            />
          </div>
          <p>
            The next important person to whom we should ever be grateful is Mr. S.
            Varadarajan, Secretary, DAV School, Adambakkam, Chennai. In 2002, when we
            wanted to start our Sri RM Jain Vidhyapeeth, an exclusive school for Plus 1
            and Plus 2 classes under the Tamil Nadu Govt. syllabus, he guided us not only
            to obtain the affiliation but also offered professional advice to implement a
            highly effective academic program which helps our students to score very high
            marks.
          </p>
          <p>
            These two great people, Padmashri Dr. Mrs. YG. Parthasarathy and Mr. S.
            Varadarajan, have contributed immensely to the growth and performance of our
            school.
          </p>
        </div>
      </section>
    </div>
  );
}

export default WeAreThankful;
