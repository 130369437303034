import React from "react";
import "./AlumniSection.css"; // Custom CSS for styling

function AlumniSection() {
  const alumni = [
    {
      name: "Dr. V. Naresh",
      qualification: "MBBS - Kilpauk Medical College, Chennai.",
      image: `${process.env.PUBLIC_URL}/img/Alumni/alumni1.jpeg`, // Replace with actual image path
    },
    {
      name: "Ms. Niveda S",
      qualification: "B.Sc., M.A., NET, JRF - Assistant Professor, MOP Vaishnav College of Women.",
      image: `${process.env.PUBLIC_URL}/img/Alumni/alumni2.jpeg`, // Replace with actual image path
    },
    {
      name: "Dr. Vasanth Ayswarya MDS",
      qualification: "Associate Professor, Dept of Periodontics, Priyadarshini Dental College and Hospital.",
      image: `${process.env.PUBLIC_URL}/img/Alumni/alumni3.jpeg`, // Replace with actual image path
    },
    {
      name: "Ms. Kaavyalakshmi",
      qualification: "B. Tech Chemical Engineering, Bank of Baroda, Designation - SWOA.",
      image: `${process.env.PUBLIC_URL}/img/Alumni/alumni4.jpeg`, // Replace with actual image path
    },
    {
      name: "S. Aishwarya",
      qualification: "B.E, PGDM (Banking), MBA (Banking and Finance), Banking Functional Consultant - Infosys Banco.",
      image: `${process.env.PUBLIC_URL}/img/Alumni/alumni5.jpeg`, // Replace with actual image path
    },
    {
      name: "Dr. Na. Priyadarshini",
      qualification: "BDS.",
      image: `${process.env.PUBLIC_URL}/img/Alumni/alumni6.jpeg`, // Replace with actual image path
    },
  ];

  return (
    <div className="alumni-section">
      <h2 className="alumni-title">Our Distinguished Alumni</h2>
      <div className="alumni-grid">
        {alumni.map((alumnus, index) => (
          <div className="alumni-card" key={index}>
            <img src={alumnus.image} alt={alumnus.name} className="alumni-photo" />
            <h3 className="alumni-name">{alumnus.name}</h3>
            <p className="alumni-qualification">{alumnus.qualification}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AlumniSection;